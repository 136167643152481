import React from 'react';
import { Link } from 'react-router-dom';
import './Contactuscard.css'; // Ensure this path is correct

export default function Contactuscard() {
  return (
    <>
      <div
        className="property-info-help1"
        id="li2"
      
      >
        <h1>Our Expert Will Help You</h1>
        <p>
          Feel Free to Contact Us at Any Time, We're Online 24/7
        </p>
        <div className="property-info-help1-buttons1">
          <Link
            to="http://wa.me/9048590485"
            target="_blank"
            className="btn btn-warning"
            rel="noreferrer"
          >
            <i className="icon icon-whatsapp"></i>
            <span>WhatsApp</span>
          </Link>
          <Link to="../contacts.html" className="btn btn-black">
            <span>Contact Us</span>
          </Link>
        </div>
      </div>
    </>
  );
}
