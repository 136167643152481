import React, { useState } from 'react';
const apiUrl = process.env.REACT_APP_API_URL;
// URL of the thumbnail image<ImageCard src=
const THUMBNAIL_URL = `images/1582871914-embassy-boulevard-banner-cedar-elevation.webp`;

const FullPageVideo = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleClick = () => {
    setIsLoaded(true);
  };

  const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    cursor: isLoaded ? 'auto' : 'pointer',
  };

  return (
    <div style={containerStyle}>
      {!isLoaded ? (
        <div
          style={{
            backgroundImage: `url(${THUMBNAIL_URL})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '24px',
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          }}
          onClick={handleClick}
        >
          <span>▶</span>
        </div>
      ) : (
        <iframe
          src="images\Embassy 01- compressed.mp4"
          title="Embassy Boulevard - Villa + Boulevard Club"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          style={iframeStyle}
          loading="lazy"
        ></iframe>
      )}
    </div>
  );
};

export default FullPageVideo;