import React from 'react';
import './Card.css';
const EmbassyOne = ({ image, title, address }) => {
  return (
    <div className="card">
      <img src={image} alt={title} className="card-image" />
      <div style={{textAlign:"center", fontSize:
        '20px', margin:"auto",height:"6rem"
      }}>
      <div className="card-overlay">
        <h2>{title}</h2>
      </div>
      <div className="card-address">{address}</div>
    </div>
    </div>
  );
};

export default EmbassyOne;