import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Router from './components/Router';


function App() {
  return (
<div> 
  
  <Header />
  <Router />
  <Footer />
  
</div>
  );
}

export default App;
