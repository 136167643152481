import React, { useState } from 'react';

const BottomFixedBox = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEnquireClick = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bottom-fixed-box full-30-width">
      <ul>
        <li>
          <a href="#" onClick={handleEnquireClick}>Enquire Now</a>
        </li>
        <li>
          <a href="tel:+919599143112">Call Now</a>
        </li>
      </ul>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleModalClose}>&times;</span>
            <h2>Enquire Now</h2>
            {/* Add your enquiry form or content here */}
          </div>
        </div>
      )}
    </div>
  );
};

// Basic CSS styles
const styles = `
.bottom-fixed-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  text-align: center;
  border-top: 1px solid #ccc;
  box-shadow: 0 -1px 5px rgba(0,0,0,0.1);
}

.bottom-fixed-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.bottom-fixed-box li {
  margin: 0 10px;
}

.bottom-fixed-box a {
  text-decoration: none;
  color: #007bff;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
`;

// Inject styles into the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default BottomFixedBox;
