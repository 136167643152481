import React, { useState, useEffect, useRef } from "react";
import parkDate from "./properties.json";
import Pin from "./pin";
import Map, {
  FullscreenControl,
  Marker,
  Popup,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";
import { useNavigate } from "react-router-dom";

function View() {
  const [showFilters, setShowFilters] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showFiltered, setShowFiltered] = useState(false);
  const [filters, setFilters] = useState({
    price: null,
    location: "",
    possessionStatus: "",
    bhkType: "All",
    projectType: "",
    projectName: ""

  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const url = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const [selectedPark, setSelectedPark] = useState(null);
  const [viewport, setViewport] = useState(null);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [dataFetched, setDataFetched] = useState(false);
  const [visiblePoints, setVisiblePoints] = useState([]);
  const mapRef = useRef();
  const [viewState, setViewState] = useState({
    latitude: 13.019930500000001,
    longitude: 77.58563967425567,
    zoom: 2,
  });


  function extractNumbersAndDecimals(str) {
    // Regular expression to match numbers including decimals
    const regex = /-?\d+(.\d+)?/g;

    // Match the string against the regular expression
    const matches = str.match(regex);

    // Convert matches to numbers
    const numbers = matches ? matches.map(Number) : [];

    return numbers;
  }

  useEffect(() => {
    if (!apiUrl) return;

    const fetchProject = async () => {
      try {
        const response = await fetch(`${apiUrl}/project_all`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        setProject(result.data);
        setDataFetched(true);
        const proj = result.data;
        setViewState((prevState) => ({
          ...prevState,
          latitude: proj[Math.floor(Math.random() * proj.length)].project_address[0].latitude,
          longitude: proj[Math.floor(Math.random() * proj.length)].project_address[0].longitude,
          zoom: 2,
        }));
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (!dataFetched) fetchProject();

    const handleResize = () => {
      setViewState((prevState) => ({
        ...prevState,
        width: window.innerWidth / 2,
        height: window.innerHeight,
      }));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [apiUrl, dataFetched]);

  useEffect(() => {
    if (dataFetched) {
      let tempProjects = project;

    
    
      if (filters.price) {
        const filprice = extractNumbersAndDecimals(filters.price.toLowerCase().replace("-", ""));
  
        if (filprice.length > 0) {
          tempProjects = tempProjects.filter((project) => {
            const priceStart = extractNumbersAndDecimals(project.price_start);
  
            if (priceStart.length > 0) {
              if (filprice.length > 1) {
                if (priceStart[0] >= filprice[0] && priceStart[0] <= filprice[1]) {
                  return true;
                }
              } else {
                if (priceStart[0] >= filprice[0]) {
                  return true;
                }
              }
            }
            return false;
          });
        }
      }



      if (filters.possessionStatus) {
        tempProjects = tempProjects.filter((project) => {
          return project.posession_status === filters.possessionStatus;
        });
      }

      if (filters.bhkType !== "All") {
        tempProjects = tempProjects.filter((project) => {
          return project.configuration.some((config) => {
            return config.Type.toLowerCase().includes(filters.bhkType.toLowerCase());
          });
        });
      }

      if (filters.projectType) {
        tempProjects = tempProjects.filter((project) => {
          return project.project_type.toLowerCase().includes(filters.projectType.toLowerCase());
        });
      }

      if (filters.projectType) {
        tempProjects = tempProjects.filter((project) => {
          return project.project_type.toLowerCase().includes(filters.projectType.toLowerCase());
        });
      }

      if (filters.location) {
        tempProjects = tempProjects.filter((project) => {
          return project.type_location.toLowerCase().includes(filters.location.toLowerCase());
        });
      }
      if (filters.projectName) {
        tempProjects = tempProjects.filter((project) => {
          return project.project_name.toLowerCase().includes(filters.projectName.toLowerCase());
        });
      }

      setFilteredProjects(tempProjects);
      console.log("filterd pro", filteredProjects);
    }
  }, [dataFetched, filters, project]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
    setShowFiltered(true);
  };

  useEffect(() => {
    if (dataFetched && mapRef.current) {
      const map = mapRef.current.getMap();
      const bounds = map.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      const filteredPoints = project.filter(
        (proj) =>
          proj.project_address[0].latitude <= ne.lat &&
          proj.project_address[0].latitude >= sw.lat &&
          proj.project_address[0].longitude <= ne.lng &&
          proj.project_address[0].longitude >= sw.lng
      );
      setVisiblePoints(filteredPoints);
      setShowFiltered(false);
    }
  }, [viewState, project, dataFetched]);

  const updateViewState = (latitude, longitude) => {
    setViewState((prevState) => ({
      ...prevState,
      latitude,
      longitude,
      zoom: 5,
      transitionDuration: 500,
    }));
  };

  const renderProject = (property) => {
    if (property.project_id) {
      return (
        <div
          className="apartments-card-sm"
          style={{
            background: "#f8f8f8",
            boxShadow: "0 3px 0 rgba(0, 0, 0, .16)",
            borderRadius: "12px",
            width: "calc(101.3% - 14px)",
            margin: "28px 7px",
            position: "relative",
          }}
        >
          <div className="apartments-img">
            <img src={`${apiUrl}/image/${property.featured_image}`} alt={property.featured_image} />
            <div>
              <div className="badge-list">
                <span className="badge" style={{ marginLeft: "auto" }}>
                  {property.project_type}
                </span>
              </div>
              <div style={{ display: "flex", marginTop: "0.5rem" }}>
                <span className="price-badge" style={{ marginLeft: "auto" }}>
                  {property.price_start}
                </span>
              </div>
            </div>
          </div>
          <div className="card-caption">
            <div className="apartments-title">
              <p>{property.project_name}</p>
            </div>
            <div className="apartments-address warning">
              <i className="icon icon-location-orange"></i>
              <p>{property.project_address[0].city}</p>
            </div>
          </div>
          <a href={`${url}/#/index1/${property.project_id}`} className="absolute-link"></a>
        </div>
      );
    } else {
      return (
        <div>
          <h3>Project Not Available</h3>
        </div>
      );
    }
  };

  const containerStyle = {
    display: "flex",
    height: "80vh",
  };

  const mapContainerStyle = {
    flex: "1",
    position: "relative",
  };

  const mapStyle = {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="filter">
      <div className="filter-bar">
       
        <div className="search-box">
          <i className="fa fa-search"></i>
          <input
            type="text"
            placeholder="Search Project Name"
            name="projectName"
            value={filters.projectName}
            onChange={handleFilterChange}
          />
        </div>
        <button className="filter-toggle-button" onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? "Hide Filters" : "Show Filters"}
        </button>
        <div className={`filter-buttons ${showFilters ? "visible" : "hidden"}`}>
          <select name="price" value={filters.price} onChange={handleFilterChange}>
            <option value="null">Select Price</option>
            <option value="2Cr-3Cr">2Cr-3Cr</option>
            <option value="3Cr-3Cr">3Cr-3Cr</option>
            <option value="3Cr-4Cr">3Cr-4Cr</option>
            <option value="4Cr-5Cr">4Cr-5Cr</option>
            <option value="5Cr-6Cr">5Cr-6Cr</option>
            <option value="6Cr-7Cr">6Cr-7Cr</option>
            <option value="7Cr-8Cr">7Cr-8Cr</option>
            <option value="Above 9Cr">Above 9Cr</option>

          </select>

          <select name="possessionStatus" value={filters.possessionStatus} onChange={handleFilterChange}>
            <option value="">Possession Status</option>
            <option value="Ready_To_Move">Ready to Move</option>
            <option value="Under_Construction">Under Construction</option>
            <option value="New_Launch">New Launch</option>
          </select>

          <select name="bhkType" value={filters.bhkType} onChange={handleFilterChange}>
            <option value="All">All BHK</option>
            <option value="3 BHK">3 BHK</option>
            <option value="4 BHK">4 BHK</option>
            <option value="5 BHK">5 BHK</option>
            <option value="6 BHK">6 BHK</option>
            <option value="7 BHK">7 BHK</option>
          </select>

          <select name="projectType" value={filters.projectType} onChange={handleFilterChange}>
            <option value="">Project Type</option>
            <option value="Apartment">Apartment</option>
            <option value="Villa">Villa</option>
            <option value="Plot">Plot</option>
          </select>

          <select name="location" value={filters.location} onChange={handleFilterChange}>
            <option value="">Project City</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Gurgaon">Gurgaon</option>
            <option value="Goa">Goa</option>
          </select>
        </div>

      </div>

      <div style={containerStyle}>
        <div className="project-list" style={{ overflowY: "scroll", height: "80vh", width: "20rem" }}>
          {showFiltered ? (
            filteredProjects.length > 0 ? (
              filteredProjects.map((property) => renderProject(property))
            ) : (
              <p>No projects found</p>
            )
          ) : (
            visiblePoints.map((property) => renderProject(property))
          )}
        </div>

        <div style={mapContainerStyle}>
          <Map
            ref={mapRef}
            {...viewState}
            onMove={(evt) => setViewState(evt.viewState)}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          >
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />

            {visiblePoints.map((park) => (
              <Marker
                key={park.project_id}
                latitude={park.project_address[0].latitude}
                longitude={park.project_address[0].longitude}
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  setSelectedPark(park);
                  updateViewState(
                    park.project_address[0].latitude,
                    park.project_address[0].longitude
                  );
                }}
              >
                <Pin />
              </Marker>
            ))}

            {selectedPark && (
              <Popup
                latitude={selectedPark.project_address[0].latitude}
                longitude={selectedPark.project_address[0].longitude}
                onClose={() => setSelectedPark(null)}
              >
                <div>
                  <h2>{selectedPark.project_name}</h2>
                  <p>{selectedPark.project_address[0].address}</p>
                  <button
                    onClick={() => navigate(`/index1/${selectedPark.project_id}`)}
                  >
                    View Details
                  </button>
                </div>
              </Popup>
            )}
          </Map>
        </div>
      </div>
    </div>
  );
}

export default View;
