import React, { useEffect, Suspense, useMemo } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './PopularLinksCss.css';

const EmbassyBoulevard = React.lazy(() => import('./EmbassyBoulevard'));
const FeaturedProject = React.lazy(() => import('./FeaturedProject'));
const NewLaunches = React.lazy(() => import('./NewLaunches'));
const ComingSoon = React.lazy(() => import('./ComingSoon'));
const FullPageVideo = React.lazy(() => import('./FullPageVideo'));
const PopularLinks = React.lazy(() => import('./PopularLinks'));

const apiUrl = process.env.REACT_APP_API_URL;

const preloadImage = (url) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'image';
  link.href = url;
  document.head.appendChild(link);
};

export function Home() {
  useEffect(() => {
    // Preload critical images
    preloadImage(`${apiUrl}/image/PROJ_IMG23`);
    preloadImage(`${apiUrl}/image/PROJ_IMG9`);
    preloadImage(`${apiUrl}/image/PROJ_IMG5`);
  }, []);

  const ImageCard = useMemo(() => {
    return React.memo(({ src, label }) => (
      <div className="apartments-card-sm">
        <div className="apartments-img">
          <img src={src} alt={label} loading="lazy" />
          <div>
            <div style={{ display: "flex", marginTop: "0.5rem" }}>
              <span className="price-badge" style={{ backgroundColor: "transparent", color: "white" }}>
                {label}
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
  }, []);

  return (
    <div>
      <div className="page-wrapper main-page" style={{ backgroundImage: 'url(img)' }}>
        <div className="main-slider-wrapper">
          <div className="request-container"></div>
          <div className="swiper main-slider">
            <div className="FullPageVideo" style={{ height: "600px", width: "100%" }}>
              <Suspense fallback={<div>Loading...</div>}>
                <FullPageVideo />
              </Suspense>
              <div className="centerLink">
                <div className="button-wrapper">
                  <a href="/#/View" className="SHowAll" style={{ color: "white", border: "double" }}>
                    Show all properties
                  </a>
                </div>
              </div>
            </div> 
            <div className="ULP">
              <div className="NEWLAUNCHES" style={{ display: "flex" }}>
                <ImageCard src={`${apiUrl}/image/PROJ_IMG23`} label="Ultra Luxury" />
                <ImageCard src={`${apiUrl}/image/PROJ_IMG9`} label="Luxury" />
                <ImageCard src={`${apiUrl}/image/PROJ_IMG5`} label="Premium" />
              </div>
            </div>
           
          </div>
        </div>
        <div className="service" id="service">
          <div className="container container-sm">
            <div className="row service-content">
              <div className="col-sm-6 service-left">
                <h1 className="service-title">
                  Professional help <span>with a personal touch</span>
                </h1>
              </div>
              <div className="col-sm-6 service-right">
                <p>
                  Welcome to <strong>Luxury Roof</strong>, where your dreams of living in a premium, luxury apartment come true.
                </p>
                <p>
                  We provide comprehensive property information and in-depth neighborhood data to help you make well-informed decisions about your new home or investment. Our dedicated team of specialists is committed to managing every aspect of your property needs—from marketing and contracting to day-to-day management—ensuring a seamless and stress-free experience for you.
                </p>
              </div>
            </div>
            <div className="service-tooltips">
              {[
                { img: 'PROJ_IMG3', title: 'What is the best property for investment?' },
                { img: 'PROJ_IMG4', title: 'What location gives highest ROI?' },
                { img: 'PROJ_IMG3', title: 'Where should I buy a new home?' },
                { img: 'PROJ_IMG6', title: 'Where is the best location to rent?' },
                { img: 'PROJ_IMG7', title: 'What documents I need to buy a property?' },
                { img: 'PROJ_IMG9', title: 'Why should I buy OffPlan property?' },
              ].map((tooltip, index) => (
                <div key={index} className={`service-tooltip tooltip-${index + 1}`}>
                  <div className="service-tooltip-img">
                    <img src={`${apiUrl}/image/${tooltip.img}`} alt={tooltip.title} loading="lazy" />
                  </div>
                  <div className="service-tooltip-caption">
                    <h6>{tooltip.title}</h6>
                    <p></p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="wrapper bg-gray">
          <div className="cards-wrapper-heading">
            <h2 className="FeaturedProjectHeading">FEATURED PROJECT</h2>
          </div>
          <div className="container container-sm">
            <div className="cards-wrapper offplan-latest">
              <Suspense fallback={<div>Loading...</div>}>
                <FeaturedProject />
                <h1 style={{ fontFamily: "Poppins", fontSize: "32px" }}>WHAT'S NEW</h1>
                <NewLaunches />
              </Suspense>
            </div>
            <div className="sentence">
              <div className="row">
                <div className="col-sm-6">
                  <div className="sentence-box dark">
                    <h2>Property <br /> management</h2>
                    <p>
                      We provide services to manage any type of property. Our experts will manage everything. We will handle marketing your apartment, contracts, and everyday needs.
                    </p>
                    <a className="text-link" href="/#/">
                      Learn more
                    </a>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="sentence-box light">
                    <h2>Additional <br /> Services</h2>
                    <p>
                      Finding your dream apartment is just the beginning. We can also help you secure financing with favorable terms and low interest rates. Let us take the stress out of the mortgage process and connect you with lenders who understand the luxury market.
                    </p>
                    <Link className="text-link" to="">
                      Learn more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ gap: "1rem" }}>
          <p className="Trending"> Trending </p>
          <Suspense fallback={<div>Loading...</div>}>
            <EmbassyBoulevard />
            <ComingSoon />
            <PopularLinks />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default Home;
