import React, { useState, useEffect } from "react";
import PopupForm from './PopupForm';
import Register from './Register';
import { Link, useParams } from "react-router-dom";

import EmbassyGrove from "./EmbassyGrove";
import RequestForm from "./RrquestForm";
import Contactuscard from "./Contactuscard";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon
} from 'react-share';
import Amenities from "./Amenities";

function Index1() {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpene, setIsOpene]=useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const url = process.env.REACT_APP_URL;
  const { project_id } = useParams();
  const [gallery_images, setGalleryImages] = useState([]);
  const [project, setProject] = useState(null);
  const [similar_project, setSimilar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loanAmount, setLoanAmount] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [loanDuration, setLoanDuration] = useState(0);
  const [monthlyInterest, setMonthlyInterest] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalRepayment, setTotalRepayment] = useState(0);
  const [totalInterestCost, setTotalInterestCost] = useState(0);
  const [selectedType, setSelectedType] = useState(null);
  const [type, setType] = useState("discription");
  const [type1, setType1] = useState("2 BHK");
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [currentUrl] = useState(window.location.href); // Use current URL
  const [message] = useState("luxuryroof.in"); // Example message

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const togglPopup = () => {
    setIsOpene(!isOpene);
  };

  const handleButtonClick = (index, type) => {
    setSelectedIndex(index);
    handleFlatSize(type);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const reset = () => {
    setLoanAmount(0);
    setInterestRate(0);
    setLoanDuration(0);
    setMonthlyInterest(0);
    setMonthlyPayment(0);
    setTotalRepayment(0);
    setTotalInterestCost(0);
  };



  useEffect(() => {
    if (!apiUrl || !project_id) {
      return; // Exit early if apiUrl or project_id is undefined
    }
    async function fetchProject() {
      try {
        const response = await fetch(`${apiUrl}/projects/${project_id}`);

        if (!response.ok) {
          throw new Error(
            `HTTP error! Status: ${response.status}, ${response.json().data}`
          );
        }

        const result = await response.json();
        console.log("Response Data:", result); // Debug log

        setProject(result.data);
        setSimilar(result.recommend);
        setError(null); // Clear previous errors
        const project = result.data;
        return project;
      } catch (err) {
        console.error("Fetch Error:", err.message); // Error log
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    console.log("Fetching Project with ID:", project_id); // Debug log
    fetchProject();
    fetchProject().then((project) => {
      setSelectedType(
        project.configuration && project.configuration.length > 0
          ? project.configuration[0].Type
          : null
      );
    });
  }, [project_id, apiUrl]);

  const handleFlatSize = (type) => {
    setSelectedType(type);
  };

  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const getIntroOfPage = (label) => {
    if (label === "Page A") {
      return "Page A is about men's clothing";
    }
    if (label === "Page B") {
      return "Page B is about women's dress";
    }
    if (label === "Page C") {
      return "Page C is about women's bag";
    }
    if (label === "Page D") {
      return "Page D is about household goods";
    }
    if (label === "Page E") {
      return "Page E is about food";
    }
    if (label === "Page F") {
      return "Page F is about baby food";
    }
    return "";
  };

  const [isFavorite, setIsFavorite] = useState(false);

  // Toggle the state when the button is clicked
  const handleClick = () => {
    setIsFavorite(!isFavorite);
  };


  const toggleShareOptions = () => {
    setShowShareOptions(!showShareOptions);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }



  return (
    <div>
      <body>
        <div class="main-wrapper">
          <div class="page-wrapper off-plan-detail-page">
          
            <div className="" >
              <h1 className="h1Size">{project.project_name}</h1>
              

 <div className="ButtonVideo" id="BV">
      
 <a href="https://www.youtube.com/@luxuryroofofficial" target="_blank" rel="noopener noreferrer" className="btn btn-success button-custom" style={{ background: "white", color: "black", display: "inline-flex", alignItems: "center" }}>
  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 7c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-3.333L22 17V7l-4 3.333V7zm-1.998 10H4V7h12l.001 4.999L16 12l.001.001.001 4.999z"></path>
  </svg>
  Video
</a>
     
      <button type="button" className="btn btn-success button-custom" style={{ background: "white", color: "black" }}>
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.024 11.536 10 10l-2 3h9l-3.5-5z"></path>
          <circle cx="9.503" cy="7.497" r="1.503"></circle>
          <path d="M19 2H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.988 5 19.806 5 19s.55-.988 1.012-1H21V4c0-1.103-.897-2-2-2zm0 14H5V5c0-.806.55-.988 1-1h13v12z"></path>
        </svg>
        Gallery
      </button>
      <button type="button" className="btn btn-success button-custom" style={{ background: "white", color: "black" }} onClick={toggleShareOptions}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share" viewBox="0 0 16 16">
          <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"></path>
        </svg>
        Share
      </button>
      {showShareOptions && (
        <div className="share-options">
          <FacebookShareButton url={currentUrl} quote={message}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={currentUrl} title={message}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <WhatsappShareButton url={currentUrl} title={message}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
      )}
      <button
      type="button"
      className="btn btn-success button-custom"
      style={{
        background: "white", // Button background color remains unchanged
        color: "black" // Text color remains unchanged
      }}
      onClick={handleClick}
    >
      <div
        style={{
          display: 'inline-block',
          backgroundColor: isFavorite ? "red" : "transparent", // Background color of the heart icon
          padding: '4px', // Add padding to ensure the background color surrounds the heart
          borderRadius: '50%' // Optional: adds rounded corners to the background
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill={isFavorite ? "white" : "black"} // Heart icon color based on state
          className="bi bi-heart"
          viewBox="0 0 16 16"
        >
          <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"></path>
        </svg>
      </div>
      <a href="/#/Register">
      Favorite
      </a>
    </button>
    {isOpen && <Register closePopup={togglePopup} />}
    </div>
            </div>
            
          <div className="project-slider swiper">
      <div className="owl-carousel owl-theme">
        {project.gallery_image.map((gallery_image, index) => (
          <div
            key={index}
            className="project-slider-item"
            style={{ width: "22rem" }}
          >
            <a
              className="project-slider-img fancybox"
              href={`${apiUrl}/image/${gallery_image}`}
              data-fancybox="gallery"
              data-caption="Your image caption here"
            >
              <img src={`${apiUrl}/image/${gallery_image}`} alt="" />
            </a>
          </div>
        ))}
      </div>
    </div>
            <br />
            <div className="nav-container-bg"
            style={{position: "sticky", top: "-10px"}}>
        <div className="nav-container">
          <ul className="nav-index" id="nav-buttonnks">
            <li style={{background:"rgb(221 218 197)",borderColor:"rgb(221 218 197)"}} onClick={() => scrollToSection('li1')}> <button className="nav-index-button">Project Detail</button> </li>
            <li style={{background:"rgb(221 218 197)",borderColor:"rgb(221 218 197)"}} onClick={() => scrollToSection('li3')}> <button className="nav-index-button">Master Plan</button></li>
            <li style={{background:"rgb(221 218 197)",borderColor:"rgb(221 218 197)"}} onClick={() => scrollToSection('li3')}> <button className="nav-index-button">Floor Plan</button></li>
            <li style={{background:"rgb(221 218 197)",borderColor:"rgb(221 218 197)"}} onClick={() => scrollToSection('Brochure')}> <button className="nav-index-button">Brochure</button></li>
            <li style={{background:"rgb(221 218 197)",borderColor:"rgb(221 218 197)"}} onClick={() => scrollToSection('li4')}> <button className="nav-index-button">USP</button></li>
            <li style={{background:"rgb(221 218 197)",borderColor:"rgb(221 218 197)"}} onClick={() => scrollToSection('Amenities')}> <button className="nav-index-button">Amenities</button></li>
            <li style={{background:"rgb(221 218 197)",borderColor:"rgb(221 218 197)"}} onClick={() => scrollToSection('Calculator')}><button className="nav-index-button">Calculator</button></li>
            <li style={{background:"rgb(221 218 197)",borderColor:"rgb(221 218 197)"}} onClick={() => scrollToSection('Location')}> <button className="nav-index-button">Location </button></li>
            <li style={{background:"rgb(221 218 197)",borderColor:"rgb(221 218 197)"}} onClick={() => scrollToSection('SimilarProjects')}> <button className="nav-index-button">Similar Projects</button></li>
            <li style={{background:"rgb(221 218 197)",borderColor:"rgb(221 218 197)"}} onClick={() => scrollToSection('faqs')}> <button className="nav-index-button">FAQs </button></li>
            
          </ul>
        </div>
      </div>
            <div class="tab-content">
              <div id="infoAbout" class="tab-pane fade active show">
                <div class="container-fluid container-sm">
                  <div class="info-tab-about">
                    <div class="info-tab-caption">
                      <h1
                        class="MsoNormal"
                        style={{ margin: "0cm;",fontFamily: "Poppins",fontSize: "27px", marginTop: "-1rem",marginLeft: "-57", display:"flex"

                        }}
                      >
                        
                      
                       
                      
                      
                      </h1>
                      <p class="MsoNormal" style={{ height: "1px" }}>
                        &nbsp;
                      </p>
                      <hr className="Description"
                        style={{
                          height: "2px",
                          borderWidth: "unset",
                          color: "gray",
                          backgroundcolor: "gray",
                          marginLeft: "-41px",
                        }}
                      />

          <h3 className="Description1" style={{marginLeft:"51px"}}><b>Description</b></h3>
                      <div class="page" title="Page 8">
                        <div class="section content-section1">
                          <div class="layoutArea">
                            <div class="column">
                              <p>
                                <span
                                  style={{
                                    fontSize: "12pt",
                                    fontFamily: "Poppins",
                                    color: "black",
                                  }}
                                >
                                  {project.about_project}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
        
            <div class="wrapper" style={{ width: "auto", marginLeft: ""  }}>
              <div class="container container-sm" style={{ display: "flex" }}>
               <div class="property-info-caption0">
                
                  <div class="property-info-item3" id="li1">
                    <div class="property-info-item0" style={{
                       color: "black" }}>
                      <p><b>Price</b></p>
                      <h1>{project.price_start}</h1>
                    </div>

                    <div class="property-info-item0">
                      <p><b>Project Type</b></p>
                      <h1 style={{wordBreak:"break-word"}}>{project.project_type}</h1>
                    </div>
                    <div class="property-info-item0">
                      <p><b>Rera Number</b></p>
                      <p>
                      <h1 style={{wordBreak:"break-word"}}>{project.AREA_number}</h1>
                      </p>
                    </div>
                   
                    <div class="property-info-item0">
                      <p><b>Land Area</b></p>
                      <h1 style={{wordBreak:"break-word"}}>{project.area_from}</h1>
                    </div>
                    <div class="property-info-item0">
                      <p><b>Location</b></p>
                      <h1  style={{wordBreak:"break-word"}}>{project.type_location}</h1>
                    </div>
                    <div class="property-info-item0">
                      <p><b>Project Unit</b></p>
                      <h1  style={{wordBreak:"break-word"}}>{project.project_unit}</h1>
                    </div>
                    <div class="property-info-item0">
                      <p><b>Total Tower</b></p>
                      <h1  style={{wordBreak:"break-word"}}>{project.total_tower}</h1>
                    </div>
                    <div className="property-info-item0">
  <p><b>Project status</b></p>
  <h1>
    {(() => {
      switch (project.posession_status) {
        case 'Ready_To_Move':
          return 'Ready To Move';
        case 'New_Launch':
          return 'New Launch';
        case 'Under_Construction':
          return 'Under Construction';
        default:
          return project.posession_status;
      }
    })()}
  </h1>
</div>

                   
                  </div>
                  <br />
                {/* <Contactuscard/> */}

                  <div
                    className="projContainer" id="li3"

                    style={{
                      border:
                        selectedIndex !== null ? "2px solid #fff" : "none",
                    }}
                  >
                    <div
                      className="SizeOfFlat"
                      style={{
                        marginLeft: "-13px",
                        display: "flex",
                        overflowX: "auto", // Allow horizontal scrolling if needed
                        gap: "1rem",
                        marginTop: "-4.5rem",
                        paddingLeft: "0rem", // Add padding for better spacing
                      }}
                    >
                      {project.configuration.map((config, index) => (
                        <button
                          key={index}
                          className="button2"
                          style={{
                          fontWeight:"700"
                            ,
                            color: "#000",
                            borderTop:
                              selectedIndex === index
                                ? "2px solid #fff"
                                : "2px solid #fff",
                            borderLeft:
                              selectedIndex === index
                                ? "2px solid #fff"
                                : "2px solid #fff",
                            borderRight:
                              selectedIndex === index
                                ? "2px solid #fff"
                                : "2px solid #fff",
                            borderBottom:
                              selectedIndex === index
                                ? "4px solid #000"
                                : "none",
                            background: "#fff",
                            padding: "0.8rem",
                          }}
                          onClick={() => handleButtonClick(index, config.Type)}
                        >
                          <span>
                            <h3>{config.Type}</h3>
                          </span>
                        </button>
                      ))}
                    </div>

                    {project.configuration.map(
                      (config, index) =>
                        selectedType === config.Type && (
                          <div key={index} className="two-sharing" style={{}}>
                            <h4 style={{fontWeight:"700", width:"5rem", marginLeft:"9px"}}>Floor Plan</h4>
                            <div
                              className="TypeOfProject"
                              style={{ marginTop: "4rem", marginLeft: "-7.3rem" }}
                            >
                              <Link
                                to={`${apiUrl}/image/${config.floor_plan}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img className="flor"
                                  style={{
                                    width: "8rem",
                                    height: "3rem",
                                    marginTop: "0rem",
                                    marginLeft: "-12px",
                                    border: "double",
                                    borderRadius:"12px"
                                  }}
                                  src={`${apiUrl}/image/${config.floor_plan}`}
                                  alt="Master plane"
                                />
                              </Link>
                            </div>
                            <h4 style={{fontWeight:"700"}}>Master Plan</h4>
                            <div
                              className="TypeOfProject"
                              style={{ marginTop: "4rem", marginLeft: "-9rem" }}
                            >
                              <Link
                                to={`${apiUrl}/image/${config.master_plan}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img className="MasterPlane"
                                  style={{
                                    width: "8rem",
                                    height: "3rem",
                                    marginTop: "0rem",
                                    marginLeft: "-12px",
                                    border: "double",
                                    borderRadius:"12px"
                                  }}
                                  src={`${apiUrl}/image/${config.master_plan}`}
                                  alt="Master plane"
                                />
                              </Link>
                            </div>
                            {/* <h4 style={{fontWeight:"700"}}>Type</h4>
                            <div className="ProjectType"
                              style={{ marginTop: "4rem", marginLeft: "-5rem", color:"rgb(112 112 112 / 80%)" }}
                            >
                              <p>{config.Type}</p>
                            </div> */}
                            {/* <h4 style={{fontWeight:"800",wordBreak:"break-word"}}>Area</h4>
                            <div className="Area"
                              style={{ marginTop: "4rem", marginLeft: "-7rem", color:"rgb(112 112 112 / 80%)" }}
                            >
                              
                              <p>{config.area}</p>
                            </div> */}
                            <div>
                           <h4>Area</h4>
                            <div
                              style={{ marginTop: "3rem", marginLeft: "0rem" }}
                            >
                              <p>{config.area}</p>
                            </div> 
                              </div>
                              <div>
                            <button
                              className="DownloadBrochure" id="Brochure"
                              style={{
                                fontWeight:"700",
                                border: "transparent",
                                background: "rgb(221 218 197)",
                                color:"black",
                                height: "39px",
                                borderRadius:"10px",
                                margin:"3px"
                              }}
                              onClick={togglPopup}
                            >
                              Download Brochure
                            </button>
                            {isOpene && <PopupForm closePopup={togglPopup} />}

                            <div>
                            <button
                                className="CompleteCostingDetail " id="CompleteCostingDetail"
                                onClick={togglPopup}
                              >
                                Complete Costing Detail
                              </button>
                              {isOpene && <PopupForm closePopup={togglPopup} />}
                            
                              
                          </div>
                              </div>
                             
                          </div>
                        )
                    )}
                  </div>

                  <div class="container "
                    style={{ marginTop: "-2rem" }}
                    id="HistoryMobile"
                  >
                    <div className="row" id="rows">
                      <div className="propery-detals-new col-md-12 offset-3">
                        <div class="row flate-image" id="imagefit"
                          style={{
                            width:"45rem !important",
                            marginLeft:"-0.5rem",
                            backgroundColor: "#fff",
                            boxShadow: "0 10px 16px rgba(46, 54, 99, .25)",
                            color: "black",

                                                     }}
                        >
                          <div class="col-md-4">
                            <img
                              style={{ width: "100%", height: "299px", marginLeft:"-6px",borderRadius:"11px"}}
                              src={`${apiUrl}/image/PROJ_IMG82`}
                              alt="Bluewaters Bay"  
                            />
                          </div>
                          <div class="col-md-4">

                          <div className="info-card" >
                            <h2>Why Invest Through Luxury Roof?</h2>
                            <p>
                              Zero Brokerage-100% , Service-0% Brokerage.
                              We charge our customers nothing.
                              Not today, notever
                            </p>
                            <div className="property-info-help1-buttons1">
          <Link
            to="http://wa.me/9048590485"
            target="_blank"
            className="btn btn-warning"
            rel="noreferrer"
          >
            <i className="icon icon-whatsapp"></i>
            <span>WhatsApp</span>
          </Link>
          <a href="/#/Contect" className="btn btn-black">
            <span>Contact Us</span>
          </a>
        </div>
                            </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                          <div class="property-info-caption" id="li4">
                            <div class="property-info-item2">
                              <img
                                style={{
                                  borderRadius: "21px",
                                  height: "-webkit-fill-available",
                                }}
                                src={`${apiUrl}/image/${project.usp_image}`}
                                alt="Bluewaters Bay"
                              />
                            </div>
                          </div>
                          
                                

                        </div>
                        <div id="Amenities">
                                  <Amenities/>
                                </div>
                        <div className="embassy-groove" id="Calculator">
                          {/* <h2 class="title-sm" style={{color:"#ffffff"}}>Property information</h2> */}
                          <EmbassyGrove />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>

                  <RequestForm />
                </div>
              </div>
            </div>

            <div class="info-tab">
              <div class="container container-sm" id="Location">
                <ul class="nav nav-tabs">
                  <li>
                    <Link data-toggle="tab" to="#">
                      Location
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="map-large">
                <iframe
                  src={`${project.project_address[0].map_api}`}
                  style={{
                    border: "0",
                    loading: "lazy",
                    referrerpolicy: "no-referrer-when-downgrade"
                  }}
                ></iframe>
              </div>
            <div class="wrapper bg-gray" id="SimilarProjects">
              <div class="container container-sm"style={{fontSize:"x-lrge"}}>
                <div className="cards-wrapper similar-projects">
                  <p className="title-sm"><b>Similar projects</b></p>
                  <div className="apartments-card-wrapper">
                    {similar_project.map((recommendation, index) => (
                      <div className="apartments-card-sm" key={index}>
                        <div className="apartments-img">
                          <img
                            src={`${apiUrl}/image/${recommendation.image}`}
                            alt={recommendation.project_name}
                          />
                         
                          <div>
                            <div className="badge-list">
                              <span className="badge">
                                {recommendation.project_type}
                              </span>
                            </div>
                            <div
                              style={{ display: "flex", marginTop: "0.5rem" }}
                            >
                              <span className="price-badge">
                                {recommendation.price}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-caption">
                          <div className="apartments-title">
                            <p>{recommendation.project_name}</p>
                          </div>
                          <div className="apartments-address warning">
                            <i className="icon icon-location-orange"></i>
                            <p>{recommendation.type_location}</p>
                          </div>
                        </div>
                        <Link
                          to={`${url}/index1/${recommendation.project_id}`}
                          className="absolute-link"
                        ></Link>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="Quetions"
                  style={{
                    gap: "1rem",
                    background: "rgb(243 243 245)",
                    padding: "2rem",
                  }}
                >
                  <div
                    className=""
                    style={{
                      transition: "all .5s ease-in-out",
                      flexShrink: "0",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1>Frequently Asked Questions(FAQs)</h1>
                   
                  </div>
                  <br />
                  {project.faq.map((faq, index) => (
                    <div id="faqs"
                      style={{
                        borderStyle: "groove",
                        background: "white",
                        borderRadius: "1rem",
                        padding: "1rem",
                        marginTop: "0.5rem",
                      }}
                    >
                      <h3>
                        Question {`${index + 1}`}: {`${faq.question}`}
                      </h3>
                      <p>
                        Answer {`${index + 1}`}: {`${faq.answer}`}
                      </p>
                    </div>
                  ))}
                </div>
                {/*                              */}
                <br />
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default Index1;

