import { useEffect } from 'react';

const TawkToChat = () => {
  useEffect(() => {
    // Define Tawk_API object
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // Insert the Tawk.to script dynamically
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/658eacd48d261e1b5f4d5a87/1hiql688d';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Append the script to the document
    const s0 = document.getElementsByTagName('script')[0];
    s0.parentNode.insertBefore(script, s0);

    return () => {
      // Cleanup script when the component unmounts
      const scriptElement = document.querySelector(`script[src='${script.src}']`);
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, []); // Empty dependency array means it runs once on mount

  return null; // This component doesn't render any visible elements
};

export default TawkToChat;
