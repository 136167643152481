import React from 'react';
import './ComingSoonCss.css';

function ComingSoon() {
  const destinations = [
    { name: 'Delhi', image: 'https://images.ctfassets.net/n2ifzifcqscw/3RZ7vx1n22JD9HfMjzpfTO/0fc6863d2a528de085bc17e9de65f35f/8963-7643-Unknown___1_.jpeg?w=500&fm=webp', comingSoon: true },
    { name: 'Goa', image: 'https://images.ctfassets.net/n2ifzifcqscw/3RZ7vx1n22JD9HfMjzpfTO/0fc6863d2a528de085bc17e9de65f35f/8963-7643-Unknown___1_.jpeg?w=500&fm=webp', comingSoon: true },
    { name: 'Gurugram', image: 'https://images.ctfassets.net/n2ifzifcqscw/4GeHuf0jq1Fd43q9bDCMpk/90edd3ef40e8da1b6e5cce843c4a29b0/exterior2__1_.jpg?w=500&fm=webp' },
    { name: 'Bangalore', image: 'https://images.ctfassets.net/n2ifzifcqscw/dR85Y1ZQwjCvAaU8MPZtu/7998038f92b5f08edce4253ad0b7a338/6-print-6-print-DJI_0042.jpeg?w=500&fm=webp' },
    { name: 'Mumbai', image: 'https://images.ctfassets.net/n2ifzifcqscw/6ASdT8uP9RmPrtaQ3p8QhR/b97cbcf7be18c13a1e262c894516ba6a/DSC04676.jpg?w=300&fm=webp', comingSoon: true },
    { name: 'Pune', image: 'https://images.ctfassets.net/n2ifzifcqscw/7H8CSO5B0uWZHL45svy1Ne/def7e4bb6867cdeb18e08ba343105e11/1610_Atlas_Peak_Rd-1000-HDR_HR__2_.jpg?w=500&fm=webp', comingSoon: true },
  ];

  const renderDestination = (destination, index, columnClass, cardClass) => (
    <div key={index} className={cardClass}>
      <img
        src={destination.image}
        alt={destination.name}
        className="destination-image"
        loading="lazy" // Lazy load images
        width="500" // Set appropriate width
        height="auto" // Maintain aspect ratio
      />
      {destination.comingSoon && <div className="coming-soon1">Coming soon</div>}
      <div className="name">{destination.name}</div>
    </div>
  );

  return (
    <div className="App1">
      <header className="App-header">
        <div className="destinations-container">
          <div className="column1">
            {renderDestination(destinations[2], 0, 'column1', 'card1')}
          </div>
          <div className="column2">
            {destinations.slice(0, 2).map((destination, index) =>
              renderDestination(destination, index, 'column2', 'card2')
            )}
          </div>
          <div className="quote-container">
            <p>“Real estate isn’t about properties, it’s about people. Let’s put you in the center.”</p>
            <a href="/#/View">See all destinations</a>
          </div>
          <div className="column3">
            {destinations.slice(4).map((destination, index) =>
              renderDestination(destination, index, 'column3', 'card3')
            )}
          </div>
          <div className="column4">
            {renderDestination(destinations[3], 0, 'column4', 'card4')}
          </div>
        </div>
      </header>
    </div>
  );
}

export default ComingSoon;