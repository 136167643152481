import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./Registercss.css"; // Ensure this CSS file is in the correct directory

const UniqueLoginRegisterForm = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    username: "", // Only used for registration
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegisterClick = (e) => {
    e.preventDefault();
    setIsRegister(true);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    setIsRegister(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = isRegister 
      ? 'http://localhost:5000/register'  // Endpoint for registration
      : 'http://localhost:5000/login';  // Endpoint for login

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
          ...(isRegister && { username: formData.username }), // Include username only for registration
        }),
      });

      const data = await response.json();

      if (response.ok) { // Check if response is successful
        alert(data.message);
        if (isRegister) {
          navigate('/login');
        } else {
          navigate('/View');  // Navigate to /View after successful login
        }
        // Reset form fields after successful submission
        setFormData({
          email: "",
          password: "",
          username: "",
        });
      } else {
        alert(data.message || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="root1">
      <div className={`unique-wrapper ${isRegister ? "unique-active" : ""}`}>
        <span className="unique-rotate-bg"></span>
        <span className="unique-rotate-bg2"></span>

        {/* Login Form */}
        <div className={`unique-form-box unique-login ${isRegister ? "unique-inactive" : ""}`}>
          <h2 className="unique-title unique-animation" style={{ "--i": 0, "--j": 21 }}>
            Login
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="unique-input-box unique-animation" style={{ "--i": 1, "--j": 22 }}>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label>Email</label>
              <i className="bx bxs-envelope"></i>
            </div>

            <div className="unique-input-box unique-animation" style={{ "--i": 2, "--j": 23 }}>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <label>Password</label>
              <i className="bx bxs-lock-alt"></i>
            </div>

            <button type="submit" className="unique-btn unique-animation" style={{ "--i": 3, "--j": 24, backgroundColor: "black" }}>
              Login
            </button>
            <div className="unique-linkTxt unique-animation" style={{ "--i": 5, "--j": 25 }}>
              <p>
                Don't have an account?
                <a href="#" className="unique-register-link" onClick={handleRegisterClick}>
                  Sign Up
                </a>
              </p>
            </div>
          </form>
        </div>

        {/* Info Text for Login */}
        <div className={`unique-info-text unique-login ${isRegister ? "unique-inactive" : ""}`}>
          <h2 className="unique-animation" style={{ "--i": 0, "--j": 20 }}>
            Welcome Back!
          </h2>
          <p className="unique-animation" style={{ "--i": 1, "--j": 21 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti, rem?
          </p>
        </div>

        {/* Register Form */}
        <div className={`unique-form-box unique-register ${isRegister ? "" : "unique-inactive"}`}>
          <h2 className="unique-title unique-animation" style={{ "--i": 17, "--j": 0 }}>
            Sign Up
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="unique-input-box unique-animation" style={{ "--i": 18, "--j": 1 }}>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
              />
              <label>Username</label>
              <i className="bx bxs-user"></i>
            </div>

            <div className="unique-input-box unique-animation" style={{ "--i": 19, "--j": 2 }}>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label>Email</label>
              <i className="bx bxs-envelope"></i>
            </div>

            <div className="unique-input-box unique-animation" style={{ "--i": 20, "--j": 3 }}>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <label>Password</label>
              <i className="bx bxs-lock-alt"></i>
            </div>

            <button type="submit" className="unique-btn unique-animation" style={{ "--i": 21, "--j": 4, backgroundColor: "black" }}>
              Sign Up
            </button>

            <div className="unique-linkTxt unique-animation" style={{ "--i": 22, "--j": 5 }}>
              <p>
                Already have an account?{" "}
                <a href="#" className="unique-login-link" onClick={handleLoginClick}>
                  Login
                </a>
              </p>
            </div>
          </form>
        </div>

        {/* Info Text for Register */}  
        <div className={`unique-info-text unique-register ${isRegister ? "" : "unique-inactive"}`}>
          <h2 className="unique-animation" style={{ "--i": 17, "--j": 0 }}>
            Welcome Back!
          </h2>
          <p className="unique-animation" style={{ "--i": 18, "--j": 1 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti, rem?
          </p>
        </div>
      </div>
    </div>
  );
};

export default UniqueLoginRegisterForm;
