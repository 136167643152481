import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation

function NewLaunches() {
    const [filters, setFilters] = useState({
        price: null,
        location: "",
        possessionStatus: "",
        bhkType: "All",
        projectType: "",
    });
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = process.env.REACT_APP_URL;
    const navigate = useNavigate(); // Correctly initialize navigate
    const [selectedPark] = useState(null);
    const [viewport, setViewport] = useState({
        latitude: 0,
        longitude: 0,
        zoom: 14,
        width: window.innerWidth / 2,
        height: window.innerHeight,
    });
    const [project, setProject] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);
    const [visiblePoints, setVisiblePoints] = useState([]);
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
    const mapRef = useRef();

    useEffect(() => {
        if (!apiUrl) return;

        const fetchProject = async () => {
            try {
                const response = await fetch(`${apiUrl}/project_all`);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const result = await response.json();
                setProject(result.data.slice(1, 4)); // Limit to the first 3 projects
                setDataFetched(true);
                const proj = result.data.slice(1, 4);
                setViewport((prevState) => ({
                    ...prevState,
                    latitude: proj[Math.floor(Math.random() * proj.length)].project_address[0].latitude,
                    longitude: proj[Math.floor(Math.random() * proj.length)].project_address[0].longitude,
                }));
                setError(null);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (!dataFetched) fetchProject();

        const handleResize = () => {
            setViewport((prevState) => ({
                ...prevState,
                width: window.innerWidth / 2,
                height: window.innerHeight,
            }));
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [apiUrl, dataFetched]);

    // Autoplay effect
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % project.length);
        }, 3000); // Change the project every 3 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [project]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="NEWLAUNCHES" style={{ display: "flex", justifyContent: "space-around", padding: "20px" }}>
            {project.map((property, index) => (
                <div
                    key={property.project_id}
                    className="apartments-card-sm"
                    style={{
                        background: "#f8f8f8",
                        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
                        borderRadius: "12px",
                        width: "calc(100% / 3 - 20px)",
                        margin: "10px",
                        position: "relative",
                        transition: "transform 0.3s ease-in-out",
                        transform: currentProjectIndex === index ? "scale(1.05)" : "scale(1)",
                        opacity: currentProjectIndex === index ? "1" : "0.6"
                    }}
                >
                    <div className="apartments-img" style={{ position: "relative" }}>
                        <img
                            src={`${apiUrl}/image/${property.featured_image}`}
                            alt={property.featured_image}
                            style={{ width: "100%", borderRadius: "12px 12px 0 0" }}
                        />
                        <div className="badge-list-vertical" style={{ position: "absolute", top: "10px", left: "10px" }}>
                            <span className="badge info" style={{ backgroundColor: "#007bff", color: "#fff", padding: "5px 10px", borderRadius: "5px" }}>
                                {property.possession_status}
                            </span>
                        </div>
                        <div className="badge-list" style={{ position: "absolute", bottom: "10px", left: "10px", display: "flex", alignItems: "center" }}>
                            <span className="badge" style={{ backgroundColor: "#fff", color: "#007bff", padding: "5px 10px", borderRadius: "5px", marginLeft: "auto" }}>
                                {property.project_type}
                            </span>
                        </div>
                    </div>
                    <div className="card-caption" style={{ padding: "15px" }}>
                        <div className="apartments-title" style={{ marginBottom: "10px" }}>
                            <p style={{ margin: "0", fontWeight: "bold", fontSize: "18px" }}>{property.project_name}</p>
                        </div>
                        <div className="apartments-address warning" style={{ display: "flex", alignItems: "center", color: "#555" }}>
                            <i className="icon icon-location-black" style={{ marginRight: "5px" }}></i>
                            <p style={{ margin: "0" }}>{property.project_address[0].city}</p>
                        </div>
                    </div>
                    <Link
                        to={`${url}/index1/${property.project_id}`}
                        className="absolute-link"
                        style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", zIndex: "1" }}
                    ></Link>
                </div>
            ))}
        </div>
    );
}

export default NewLaunches;
