import React from 'react'

function Developer() {
    return (
        <div>

            <body>
                <div class="main-wrapper">
                    <div class="page-wrapper developers-page">
                        <div class="developers-slider">
                            <div class="container container-sm">
                                <div class="developers-slider-row">
                                    <ul class="breadcrumb">
                                        <li>
                                            <a class="back-link" href="index.html">Back</a>
                                        </li>
                                        <li class="home">
                                            <a
                                                href="index.html">Main</a>
                                        </li>
                                        <li>Developers in Dubai</li>
                                    </ul>
                                    <h1>Developers in Dubai</h1>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper">
                            <div class="container container-sm">
                                <div class="sorting-box">
                                    <div class="sorting-value">
                                        <p>18 developer(s)</p>
                                    </div>
                                    <div class="search-tool">
                                        <form action="https://arbatrealty.com/developers">
                                            <button>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M3.75 11C3.75 6.99594 6.99594 3.75 11 3.75C15.0041 3.75 18.25 6.99594 18.25 11C18.25 15.0041 15.0041 18.25 11 18.25C6.99594 18.25 3.75 15.0041 3.75 11ZM11 2.25C6.16751 2.25 2.25 6.16751 2.25 11C2.25 15.8325 6.16751 19.75 11 19.75C13.1462 19.75 15.112 18.9773 16.6342 17.6949L19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L17.6949 16.6342C18.9773 15.112 19.75 13.1462 19.75 11C19.75 6.16751 15.8325 2.25 11 2.25Z"
                                                        fill="#8E8E8E" />
                                                </svg>
                                            </button>
                                            <input type="text" name="query" value="" />
                                        </form>
                                    </div>
                                </div>
                                <div class="developers-wrapper">
                                    <div class="developers-card-wrapper">
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/rem-odit-debitis-assumenda-iure.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/PyENknF8BDqvgDTO1tOU.png" alt="EMAAR PROPERTIES" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/nisi-labore-ea-nihil-et-quam.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/NFLRuf2RvUrMnIGRj4b1.png" alt="DAMAC PROPERTIES" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/ducimus-perferendis-corrupti-ut-totam-veritatis-error-omnis.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/DkBSr0V83sASKxrfJc7v.jpg" alt="SELECT GROUP" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/in-odio-incidunt-molestias-nostrum.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/articles/July2022/62c3b51b316e1.png" alt="DUBAI PROPERTIES" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/molestiae-culpa-maiores-debitis-quidem.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/articles/July2022/62c3b51b32b2c.png" alt="MERAAS" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/laudantium-hic-quam-qui-dolorem-quia-sint-dolore.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/YxDsvOfnO8nUvjKkCPie.jpg" alt="SOBHA REALTY" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/sit-iste-sunt-necessitatibus-accusantium-est-minima.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/idMmLJfFkgOfWJAoIFv5.png" alt="DANUBE PROPERTIES" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/vel-ea-minima-ad-minima.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/mO6ECltBOpKooYvNJqdz.png" alt="MAG" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/sunt-in-explicabo-numquam-dolore-omnis-voluptatem-magnam.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/QcmpEFX7unxMN2WJEEG3.png" alt="NAKHEEL" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/ex-placeat-aut-veniam-fugit-at-nemo.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/ijVNFL5qaK3dBgQx7BXq.jpg" alt="AZIZI DEVELOPMENTS" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/voluptatem-quod-consectetur-enim-nihil-quia-et.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/8PGlySNCTjgadHNLAwov.png" alt="ELLINGTON PROPERTIES" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/excepturi-ipsa-sed-non-delectus-ad-odit.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/ybz9Yiu84eGoTMNozsx1.jpg" alt="OMNIYAT" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/tenetur-ea-et-nulla-eos.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/July2022/DMrjswWDr8OsfG8siHLb.webp" alt="DAR AL ARKAN" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/oro24.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/August2022/n0hRpa9xivkkIrTHuG98.png" alt="ORO24" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/palma-holding.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/August2022/wOy8Yc09SOSBEW1JFf89.png" alt="PALMA HOLDING" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/fakhruddin-properties.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/September2022/clOaDwJUoxjTVSCi1fJ1.png" alt="FAKHRUDDIN PROPERTIES" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/samana-developers.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/September2022/16dG3SomaFqwevU0z316.png" alt="SAMANA DEVELOPERS" />
                                                </a>
                                            </div>
                                        </div>
                                        <div class="developers-card">
                                            <div class="developers-card-inner">
                                                <a href="developers/prestige-one.html"
                                                    class="developers-img">
                                                    <img src="storage/cache/resize164x164/developers/May2023/S1D8AykFOVXQwwljRiF5.webp" alt="Prestige One" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
               
            </body>
        </div>
    )
}

export default Developer
