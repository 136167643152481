import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

import "./EmbassyGroveCss.css";
// Assuming you have a CSS file for styling

function EmbassyGrove() {
  const [loanAmount, setLoanAmount] = useState(500000);
  const [loanTenure, setLoanTenure] = useState(10);
  const [interestRate, setInterestRate] = useState(9);
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const chartRef = useRef(null);
  const myChartRef = useRef(null);

  const calculateEMI = () => {
    const principal = loanAmount;
    const tenure = loanTenure * 12; // Convert years to months
    const rate = interestRate / 12 / 100; // Monthly interest rate

    const emiValue = principal * rate * (Math.pow(1 + rate, tenure) / (Math.pow(1 + rate, tenure) - 1));
    const totalAmt = emiValue * tenure;
    const totalInt = totalAmt - principal;

    setEmi(Math.round(emiValue));
    setTotalInterest(Math.round(totalInt));
    setTotalAmount(Math.round(totalAmt));
  };

  useEffect(() => {
    calculateEMI();
  }, [loanAmount, loanTenure, interestRate]);

  useEffect(() => {
    if (myChartRef.current) {
      myChartRef.current.destroy();
    }
    const ctx = chartRef.current.getContext('2d');
    myChartRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Principal', 'Interest'],
        datasets: [
          {
            data: [loanAmount, totalInterest],
            backgroundColor: ['rgb(221 218 197)', '#9e9e9e'],
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.raw !== null) {
                  label += new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'INR',
                    minimumFractionDigits: 0,
                  }).format(context.raw);
                }
                return label;
              },
            },
          },
        },
      },
    });
  }, [totalInterest, loanAmount]);

  return (
    <div className="app">
      <div className="calculator">
        <h2 style={{color:"rgb(221 218 197)"}}><b>EMI Calculator</b></h2>
        <div className="input-group">
          <label>Loan Amount (Rs.):</label>
          <input
            type="number"
            value={loanAmount}
            onChange={(e) => setLoanAmount(parseFloat(e.target.value))}
          />
          <input
            type="range"
            min="1000"
            max="100000000"
            value={loanAmount}
            onChange={(e) => setLoanAmount(parseFloat(e.target.value))}
          />
        </div>
        <div className="input-group">
          <label>Loan Tenure (Year):</label>
          <input
            type="number"
            value={loanTenure}
            onChange={(e) => setLoanTenure(parseFloat(e.target.value))}
          />
          <input
            type="range"
            min="1"
            max="30"
            value={loanTenure}
            onChange={(e) => setLoanTenure(parseFloat(e.target.value))}
          />
        </div>
        <div className="input-group">
          <label>Interest Rate (%):</label>
          <input
            type="number"
            value={interestRate}
            onChange={(e) => setInterestRate(parseFloat(e.target.value))}
          />
          <input
            type="range"
            min="0.1"
            max="50"
            step="0.1"
            value={interestRate}
            onChange={(e) => setInterestRate(parseFloat(e.target.value))}
          />
        </div>
        
      </div>

      <div className="chart">
        <canvas className='canva' ref={chartRef}></canvas>
      </div>

      <div className="results">
        <div className="result-group">
          <h3>Monthly Loan EMI:</h3>
          <p>{emi} ₹</p>
        </div>
        <div className="result-group">
          <h3>Principal Amount:</h3>
          <p>{loanAmount} ₹</p>
        </div>
        <div className="result-group">
          <h3>Total Interest:</h3>
          <p>{totalInterest} ₹</p>
        </div>
        <div className="result-group">
          <h3>Total Amount:</h3>
          <p>{totalAmount} ₹</p>
        </div>
      </div>
     
    </div>
  );
}

export default EmbassyGrove;
