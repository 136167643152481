import React, { useState } from 'react';
import Sidebar from './Sidebar'; // Import the Sidebar component
import './Header.css'; // Ensure you have this CSS file

function Header() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
       setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div>
            <header>
                <div className="container2">
                    <div className="header-row">
                        <a className="header-logo" href="/#/" style={{ marginRight: '19px', width: "8rem" }}>
                            <img
                                style={{ height: "7rem", width: "9rem", marginLeft: "-3px" }}
                                src="images/luxury-roof-logo.webp"
                                alt="Home"
                            />
                        </a>
                        <div className="mobile-menu">
                            <div className="header-nav-wrapper">
                                <button className="btn-plain toggle-header-nav">
                                    <span></span>
                                </button>
                                <ul className="header-nav">
                                    <li>
                                        <a className="header-nav-link" href="/#/">Home</a>
                                    </li>
                                    <li>
                                        <a className="header-nav-link" href="/#/">Developer/Off Plan</a>
                                    </li>
                                    <li>
                                        <a className="header-nav-link" href="/#/">Resources</a>
                                    </li>
                                    <li>
                                        <a className="header-nav-link" href="/#/">Buy</a>
                                    </li>
                                    <li>
                                        <a className="header-nav-link" href="/#/">Career</a>
                                    </li>
                                    <li>
                                        <a className="header-nav-link" href="/#/">Our team</a>
                                    </li>
                                    <li>
                                        <a className="header-nav-link" href="/#/Contect">Contact us</a>
                                    </li>
                                </ul>
                            </div>
                            <a className="header-contact" href="tel: 90485 90485">
                                <i className="icon icon-phone"></i>
                                <span>|</span>
                                <span style={{ whiteSpace: 'nowrap', fontSize: "23px" }}> 90485 90485</span>
                            </a>
                        </div>
                        <button className="btn-plain toggle-mobile-menu" onClick={toggleSidebar}>
                            <span className="menu-icon">&#9776;</span> {/* Hamburger icon */}
                        </button>
                    </div>
                </div>
            </header>
            <div className={isSidebarOpen ? "mobile-sidebar open" : "mobile-sidebar"}>
                <Sidebar toggleSidebar={toggleSidebar} /> {/* Pass the toggleSidebar function as a prop */}
            </div>
        </div>
    );
}

export default Header;