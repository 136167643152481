import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';

function NewLaunches() {
    const [viewport, setViewport] = useState({
        latitude: 0,
        longitude: 0,
        zoom: 14,
        width: window.innerWidth / 2,
        height: window.innerHeight,
    });
    const [project, setProject] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = process.env.REACT_APP_URL;

    useEffect(() => {
        if (!apiUrl) return;

        const fetchProject = async () => {
            try {
                const response = await fetch(`${apiUrl}/project_all`);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const result = await response.json();
                
                if (!result.data || result.data.length === 0) {
                    throw new Error('No data found');
                }

                const projects = result.data.slice(5, 8); // Limit to the first 3 projects
                setProject(projects);

                const proj = projects[Math.floor(Math.random() * projects.length)];
                if (proj?.project_address?.[0]) {
                    setViewport(prevState => ({
                        ...prevState,
                        latitude: proj.project_address[0].latitude || 0,
                        longitude: proj.project_address[0].longitude || 0,
                    }));
                }

                setError(null);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProject();

        const handleResize = () => {
            setViewport(prevState => ({
                ...prevState,
                width: window.innerWidth / 2,
                height: window.innerHeight,
            }));
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [apiUrl]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="NEWLAUNCHES" style={{ display: "flex",overflow:"hidden",scrollBehavior:"auto" }}>
            {project.map((property) => (
                <div
                    key={property.project_id}
                    className="apartments-card-sm"
                >
                    <div className="apartments-img">
                        <img
                            src={`${apiUrl}/image/${property.featured_image}`}
                            alt={property.featured_image}
                            loading="lazy" // Lazy load images to improve LCP
                            width="100%" // Use responsive image width
                            height="auto" // Maintain aspect ratio
                        />
                        <div className="badge-list-vertical">
                            <span className="badge info">{property.possession_status}</span>
                        </div>
                        <div className="badge-list">
                            <span className="badge">{property.project_type}</span>
                        </div>
                        <div className="price-info">
                            <span className="price-badge">{property.price_start}</span>
                        </div>
                    </div>
                    <div className="card-caption">
                        <div className="apartments-title">
                            <p>{property.project_name}</p>
                        </div>
                        <div className="apartments-address warning">
                            <i className="icon icon-location-black"></i>
                            <p>{property.project_address?.[0]?.city || 'N/A'}</p>
                        </div>
                    </div>
                    <Link
                        to={`${url}/index1/${property.project_id}`}
                        className="absolute-link"
                    ></Link>
                </div>
            ))}
        </div>
    );
}

export default NewLaunches;
