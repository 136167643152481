import React from 'react'
import { BrowserRouter,HashRouter, Route, Routes } from 'react-router-dom'
import Management from './Management'
import Developer from './Developer'
import Buy from './Buy'
import Home from './Home'
import Sale from './Sale'
import Contect from './Contect'
import Index1 from './Index1'
import View from './View'
import EmbassyGrove from './EmbassyGrove'
import EmbassyBoulevard from './EmbassyBoulevard'
import EmbassyOne from './EmbassyOne'
import Listing from './Listing '
import Testing from './Testing'
import Card1 from './Card1'
import RrquestForm from './RrquestForm'
import FeaturedProject from './FeaturedProject'
import ComingSoon from './ComingSoon'
import HomeSlider from './HomeSlider'
import Sidebar from './Sidebar'
import NewLaunches from './NewLaunches'
import AutoCard from './AutoCard'
import GalleryImage from './GalleryImage'
import PopupForm from './PopupForm'
import FullPageVideo from './FullPageVideo'
import Contactuscard from './Contactuscard'
import BottomFixedBox from './BottomFixedBox'
import Amenities from './Amenities'
import PopularLinks from './PopularLinks'
import Register from './Register'
import Login from './Login'
import RegisterLoginePage from './RegisterLoginePage'
import Competition from './Competition'
import TawkToChat from './TawkToChat'
import ClockWithNoise from './ClockWithNoise'
// import PickData from './PickData'

function Router() {
    return (
           <>  
            {/* <Header /> */}
            <HashRouter>
                <Routes>
                    
                    <Route exact path="/management" element={<Management />}/>
                    <Route exact path="/developer" element={<Developer />}/>
                    <Route exact path="/buy" element={<Buy />}/>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/index1/:project_id" element={<Index1/>}/>
                    <Route exact path="/sale" element={<Sale/>}/>
                    <Route exact path="/contect" element={<Contect/>}/>
                    <Route exact path="View" element={<View/>}/>
                    <Route exact path="Listing" element={<Listing/>}/>
                    <Route exact path="Card1" element={<Card1/>}/>
                    <Route exact path="EmbassyGrove" element={<EmbassyGrove/>}/>
                    <Route exact path="EmbassyBoulevard" element={<EmbassyBoulevard/>}/>
                    <Route exact path="EmbassyOne" element={<EmbassyOne/>}/>
                    <Route exact path="Testing" element={<Testing/>}/>
                    <Route exact path="RrquestForm" element={<RrquestForm/>}/>
                    <Route exact path="FeaturedProject" element={<FeaturedProject/>}/>
                    <Route exact path="HomeSlider" element={<HomeSlider/>}/>
                    <Route exact path="ComingSoon" element={<ComingSoon/>}/>
                    <Route exact path="Sidebar" element={<Sidebar/>}/>
                    <Route exact path="NewLaunches" element={<NewLaunches/>}/>
                    <Route exact path="AutoCard" element={<AutoCard/>}/>
                    <Route exact path="GalleryImage" element={<GalleryImage/>}/>
                    <Route exact path="PopupForm" element={<PopupForm/>}/>
                    <Route exact path="FullPageVideo" element={<FullPageVideo/>}/>
                    <Route exact path="Contactuscard" element={<Contactuscard/>}/>
                    <Route exact path="BottomFixedBox" element={<BottomFixedBox/>}/>
                    <Route exact path="Amenities" element={<Amenities/>}/>
                    <Route exact path="PopularLinks" element={<PopularLinks/>}/>
                    <Route exact path="Register" element={<Register/>}/>
                    <Route exact path="Login" element={<Login/>}/>
                    <Route exact path="RegisterLoginePage" element={<RegisterLoginePage/>}/>TawkToChat
                    <Route exact path="Competition" element={<Competition/>}/>
                    <Route exact path="TawkToChat" element={<TawkToChat/>}/>
                    <Route exact path="ClockWithNoise" element={<ClockWithNoise/>}/>
                </Routes>
                
            </HashRouter>
            {/* <Footer /> */}
        </>
    )
}

export default Router
