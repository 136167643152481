import React from 'react';
import Slider from 'react-slick';
import EmbassyOne from './EmbassyOne';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import './Blabard.css';
// import slide_image_1 from '../assets/image/slide_image_1.png';
import slide_image_2 from '../assets/image/slide_image_2.png';
// import slide_image_3 from '../assets/image/slide_image_3.png';

const EmbassyBoulevard = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const url = process.env.REACT_APP_URL;
  const navigate = useNavigate();

  const cards = [
    {
      image: `${apiUrl}/image/PROJ_IMG231`,
      title: 'Godrej Zenith',
      address: 'Sector 89 Gurgaon',
      url: '/index1/PROJ15', // URL for the first project
    },
    {
      image: `${apiUrl}/image/PROJ_IMG256`,
      title: 'Silverglades',
      address: `Sector 59`,
      url: '/index1/PROJ8', // URL for the second project
    },
    {
      image:  `${apiUrl}/image/PROJ_IMG210`,
      title: 'Sobha Dream Garden',
      address: 'Thanisandra Main Road, Bengaluru',
      url: '/index1/PROJ13', // URL for the third project
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '0',
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleClick = (url) => {
    navigate(url); // Navigate to the specified URL
  };

  return (
    
    <Slider {...settings}>
      {cards.map((card, index) => (
        <div key={index} onClick={() => handleClick(card.url)}>
          <EmbassyOne image={card.image} title={card.title} address={card.address} />
        </div>
      ))}
    </Slider>
  );
};

export default EmbassyBoulevard;
