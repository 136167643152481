import React from 'react';
import { FaBeer, FaCoffee, FaWater } from 'react-icons/fa'; // Remove FaBuildingColumns
import { MdOutlineIceSkating, MdElderlyWoman, MdCellWifi } from 'react-icons/md';
import { GiWaterfall, GiRoad, GiCardPlay } from 'react-icons/gi';
import { LiaGripfire, LiaCarSolid, LiaConciergeBellSolid, LiaIntercom } from 'react-icons/lia';
import { IoBusinessOutline } from 'react-icons/io5';
import { PiSwimmingPoolThin } from 'react-icons/pi';
import { BsCloudRain } from 'react-icons/bs';
import { TbAerialLift } from 'react-icons/tb';
import { BiCctv } from 'react-icons/bi';
import './Amenitie.css';

const Amenities = () => {
  const amenities = [
    { icon: <FaBeer />, label: 'Amphitheater' }, // Replace FaBuildingColumns
    { icon: <FaCoffee />, label: 'Cricket Pitch' }, // Replace FaCampground
    { icon: <MdOutlineIceSkating />, label: 'Skating Rink' },
    { icon: <GiWaterfall />, label: 'Fountains' },
    { icon: <LiaGripfire />, label: 'Fire Sprinklers' },
    { icon: <FaWater />, label: '24X7 Water Supply' },
    { icon: <LiaCarSolid />, label: 'Car Parking' },
    { icon: <LiaConciergeBellSolid />, label: 'Concierge Service' },
    { icon: <MdElderlyWoman />, label: 'Senior Citizen Siteout' },
    { icon: <GiRoad />, label: 'Internal Roads' },
    { icon: <IoBusinessOutline />, label: 'Business Center' },
    { icon: <LiaIntercom />, label: 'Intercom' },
    { icon: <GiCardPlay />, label: 'Card Room' },
    { icon: <PiSwimmingPoolThin />, label: 'Swimming Pool' },
    { icon: <BsCloudRain />, label: 'Rain Water Harvesting' },
    { icon: <TbAerialLift />, label: 'Lift(s)' },
    { icon: <MdCellWifi />, label: 'Wifi' },
    { icon: <BiCctv />, label: 'Video Door Security' },
  ];

  return (
    <div className="property-info-caption1">
      {amenities.map((amenity, index) => (
        <div key={index} className="property-info-item">
          <span className="property-info-icon">{amenity.icon}</span>
          <span className="property-info-label">{amenity.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Amenities;
