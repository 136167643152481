import React, { useState, useEffect, useRef } from "react";
import Pin from "./pin";
import Map, {
  FullscreenControl,
  Marker,
  Popup,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";
import { useNavigate } from "react-router-dom";

function Competition() {
  const [showFilters, setShowFilters] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showFiltered, setShowFiltered] = useState(false);
  const [filters, setFilters] = useState({
    price: null,
    location: "",
    possessionStatus: "",
    bhkType: "All",
    projectType: "",
    projectName: "",
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const url = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const [selectedPark, setSelectedPark] = useState(null);
  const [viewState, setViewState] = useState({
    latitude: 13.019930500000001,
    longitude: 77.58563967425567,
    zoom: 2,
  });
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const mapRef = useRef();
  const [dataFetched, setDataFetched] = useState(false);
  const [visiblePoints, setVisiblePoints] = useState([]);

  function extractNumbersAndDecimals(str) {
    const regex = /-?\d+(.\d+)?/g;
    const matches = str.match(regex);
    return matches ? matches.map(Number) : [];
  }

  useEffect(() => {
    if (!apiUrl) return;

    const fetchProject = async () => {
      try {
        const response = await fetch(`${apiUrl}/project_all`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        setProject(result.data);
        setDataFetched(true);
        const proj = result.data;
        setViewState((prevState) => ({
          ...prevState,
          latitude: proj[Math.floor(Math.random() * proj.length)].project_address[0].latitude,
          longitude: proj[Math.floor(Math.random() * proj.length)].project_address[0].longitude,
          zoom: 2,
        }));
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (!dataFetched) fetchProject();
  }, [apiUrl, dataFetched]);

  useEffect(() => {
    if (dataFetched) {
      let tempProjects = project;
      if (filters.price) {
        const filprice = extractNumbersAndDecimals(filters.price.toLowerCase().replace("-", ""));
        if (filprice.length > 0) {
          tempProjects = tempProjects.filter((project) => {
            const priceStart = extractNumbersAndDecimals(project.price_start);
            if (priceStart.length > 0) {
              if (filprice.length > 1) {
                return priceStart[0] >= filprice[0] && priceStart[0] <= filprice[1];
              } else {
                return priceStart[0] >= filprice[0];
              }
            }
            return false;
          });
        }
      }
      if (filters.possessionStatus) {
        tempProjects = tempProjects.filter(
          (project) => project.posession_status === filters.possessionStatus
        );
      }
      if (filters.bhkType !== "All") {
        tempProjects = tempProjects.filter((project) =>
          project.configuration.some((config) =>
            config.Type.toLowerCase().includes(filters.bhkType.toLowerCase())
          )
        );
      }
      if (filters.projectType) {
        tempProjects = tempProjects.filter((project) =>
          project.project_type.toLowerCase().includes(filters.projectType.toLowerCase())
        );
      }
      if (filters.location) {
        tempProjects = tempProjects.filter((project) =>
          project.type_location.toLowerCase().includes(filters.location.toLowerCase())
        );
      }
      if (filters.projectName) {
        tempProjects = tempProjects.filter((project) =>
          project.project_name.toLowerCase().includes(filters.projectName.toLowerCase())
        );
      }
      setFilteredProjects(tempProjects);
      setShowFiltered(true);
    }
  }, [dataFetched, filters, project]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
    setShowFiltered(true);
  };

  const renderProject = (property) => {
    if (property.project_id) {
      return (
        <div className="apartments-card-sm">
          <div className="apartments-img">
            <img src={`${apiUrl}/image/${property.featured_image}`} alt={property.featured_image} />
            <div>
              <div className="badge-list">
                <span className="badge">{property.project_type}</span>
              </div>
              <span className="price-badge">{property.price_start}</span>
            </div>
          </div>
          <div className="card-caption">
            <div className="apartments-title">
              <p>{property.project_name}</p>
            </div>
            <div className="apartments-address warning">
              <i className="icon icon-location-orange"></i>
              <p>{property.project_address[0].city}</p>
            </div>
          </div>
          <a href={`${url}/#/index1/${property.project_id}`} className="absolute-link"></a>
        </div>
      );
    } else {
      return <div><h3>Project Not Available</h3></div>;
    }
  };

  return (
    <div className="competition-project">
      {/* First Filter Section */}
      <div className="filter-bar">
        <input type="text" name="projectName" placeholder="Search Project Name" value={filters.projectName} onChange={handleFilterChange} />
        <select name="price" value={filters.price} onChange={handleFilterChange}>
          <option value="">Select Price</option>
          <option value="2Cr-3Cr">2Cr-3Cr</option>
          <option value="3Cr-4Cr">3Cr-4Cr</option>
          <option value="4Cr-5Cr">4Cr-5Cr</option>
        </select>
        <select name="possessionStatus" value={filters.possessionStatus} onChange={handleFilterChange}>
          <option value="">Possession Status</option>
          <option value="Ready_To_Move">Ready to Move</option>
        </select>
        {/* Add other filters here */}
      </div>

      {/* Second Filter Section */}
      <div className="filter-bar">
        <input type="text" name="projectName" placeholder="Search Project Name" value={filters.projectName} onChange={handleFilterChange} />
        <select name="price" value={filters.price} onChange={handleFilterChange}>
          <option value="">Select Price</option>
          <option value="2Cr-3Cr">2Cr-3Cr</option>
          <option value="3Cr-4Cr">3Cr-4Cr</option>
          <option value="4Cr-5Cr">4Cr-5Cr</option>
        </select>
        <select name="possessionStatus" value={filters.possessionStatus} onChange={handleFilterChange}>
          <option value="">Possession Status</option>
          <option value="Ready_To_Move">Ready to Move</option>
        </select>
        {/* Add other filters here */}
      </div>

      <div className="map-section">
        <Map
          ref={mapRef}
          {...viewState}
          onMove={(evt) => setViewState(evt.viewState)}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <ScaleControl />
          {visiblePoints.map((park) => (
            <Marker
              key={park.project_id}
              latitude={park.project_address[0].latitude}
              longitude={park.project_address[0].longitude}
            >
              <Pin />
            </Marker>
          ))}
          {selectedPark && (
            <Popup
              latitude={selectedPark.project_address[0].latitude}
              longitude={selectedPark.project_address[0].longitude}
              onClose={() => setSelectedPark(null)}
            >
              <h2>{selectedPark.project_name}</h2>
              <p>{selectedPark.project_address[0].address}</p>
            </Popup>
          )}
        </Map>
      </div>

      <div className="project-list">
        {filteredProjects.length > 0
          ? filteredProjects.map((property) => renderProject(property))
          : <p>No projects found</p>}
      </div>
    </div>
  );
}

export default Competition;
