import React from 'react'

function Sale() {
    return (
        <div>
            <body>
                <div class="main-wrapper">


                    <div class="page-wrapper management-page">
                        <div class="wrapper">
                            <div class="container container-sm">
                                <ul class="breadcrumb">
                                    <li>
                                        <a class="back-link" href="index.html">Back</a>
                                    </li>
                                    <li class="home">
                                        <a
                                            href="index.html">Main</a>
                                    </li>
                                    <li>Sell</li>
                                </ul>
                                <h1 class="title-lg">Sell</h1>
                                <div class="markdown">
                                    <p>Initiating the process of property sale in Dubai is perhaps the most confusing part, especially if it&rsquo;s your first time. A lot of people aren&rsquo;t sure about where to begin and how to proceed, but that ends here. To begin with, let&rsquo;s first look at the things you need to do before you actually sell property in Dubai.</p>
                                    <ul>
                                        <li>FIND A GOOD REAL ESTATE AGENT</li>
                                        <li>FORM A ADVERTISING YOUR PROPERTY SIGNING FORM F</li>
                                        <li>GETTING THE NOC TRANSFER OF OWNERSHIP AT DUBAI LAND DEPARTMENT</li>
                                    </ul>
                                    <h4>QUICK RECAP: DOCUMENTS REQUIRED TO SELL PROPERTY IN DUBAI</h4>
                                    <p>For the entire process of selling property in Dubai, you need the following documents. &bull; FORM A for property marketing</p>
                                    <ul>
                                        <li>FORM F/MOU to sell property in Dubai</li>
                                        <li>NOC from the developer stating the seller has no liability on the property in question</li>
                                        <li>Original passports with valid visas to apply for NOC</li>
                                        <li>Original Title Deed to apply for NOC and transfer property to the buyer.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper bg-gray py-5">
                        <div class="container container-sm">
                            <div class="request">
                            <div class="request-right">
                                            <div class="request-img">
                                                <img src="storage/settings/July2023/w19t5SHP6catfCCK7J2D.jpg" alt="" />
                                            </div>
                                        </div>
                                <div class="request-left">
                                    <div class="request-title">
                                        <h2>Make a <span>Request</span></h2>
                                        <p>We cooperate with the most proven developers in Dubai</p>
                                    </div>
                                    
                   <form className="request-form" id="slide_main-form" method="POST"
                                    action="https://arbatrealty.com/orders">
                                    <input type="hidden" name="_token" value="KOfYFMZSHu6QgZy99OgdpfHwmpevggP5Jb3BUCXz" />
                                    <input id="object" name="object" type="hidden" value="" />
                                    <div className="half-form">
                                        <div className="form-group">
                                            <i className="icon icon-user"></i>
                                            <input id="name" name="name" type="text" className=" is-valid "
                                                placeholder="Your name" value="" />
                                        </div>
                                        <div className="form-group">
                                            <i className="icon icon-smartphone"></i>
                                            <input id="phone" name="phone" type="text" value=""
                                                className="phone-mask  is-valid " placeholder="Phone number" />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group">
                                        <i className="icon icon-email"></i>
                                        <input id="email" name="email" type="email" className=" is-valid " placeholder="E-mail"
                                            value="" />
                                    </div>
                                    <div className="form-group textarea-form">
                                        <i className="icon icon-text"></i>
                                        <textarea id="message" name="message" rows="4" placeholder="Message"
                                            className=" is-valid "></textarea>
                                    </div>
                                    <input type="hidden" name="g-recaptcha-response"
                                        id="g-recaptcha-response-65b66653c3bb1" />


                                    <div className="request-form-bottom">
                                        <div className="accept-form">
                                            <label className="checkbox">
                                                <input type="checkbox" checked id="accept" name="accept" className=" is-valid " />
                                                <span className="checkmark"></span>
                                            </label>
                                            <p>I accept the <a href="#">Terms and Conditions</a></p>
                                        </div>
                                        <button className="btn btn-black" type="submit">
                                            <span>Submit</span>
                                            <i className="icon icon-send"></i>
                                        </button>
                                    </div>
                                </form>
                                
                                       
                                </div>
                                </div>
                        </div>
                    </div>

                </div>
                <script src="js/appb890.js?id=af26273185fa9102fdff80c232f2a52d"></script>
                <script
                    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCWfwYpQ-RyoU2KuY5wiTepvii3KthiOpY&amp;callback=window.initMap&amp;v=weekly"
                    defer></script>
                {/* <!-- Google Tag Manager (noscript) --> */}
                <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK63FD5M"
                    height="0" width="0" style={{display:"none",visibility:"hidden"}}></iframe></noscript>
                {/* <!-- End Google Tag Manager (noscript) --> */}
            </body>
        </div>
    )
}

export default Sale
