import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const PopularLinksSection = ({ title, links }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="je2-popular-links__section">
      <h2 className="je2-popular-links__label">{title}</h2>
      <ul className="je2-popular-links__list">
        {links.slice(0, showMore ? links.length : 5).map((link, index) => (
          <li key={index}>
            <a href={link.href}>{link.text}</a>
          </li>
        ))}
      </ul>
      {links.length > 5 && (
        <button onClick={toggleShowMore} className="je2-popular-links__more">
          <div className="je2-popular-links__more-open">{showMore ? 'Show less' : 'Show all'}</div>
          {/* <div className="je2-popular-links__more-close">{showMore ? 'Show less' : 'Show all'}</div> */}
        </button>
      )}
    </div>
  );
};

const PopularLinks = () => {
  const linksData = [
    {
      title: 'Projects In Gurgaon',
      links: [
        { href: '', text: 'Luxury Projects in Gurgaon' },
        { href: '', text: 'Luxury Apartments in Gurgaon' },
        { href: '', text: 'Luxury Flats in Gurgaon' },
        { href: '', text: 'Luxury Duplexes in Gurgaon' },
        { href: '', text: 'Luxury Floors in Gurgaon' },
        { href: '', text: 'Luxury Homes in Gurgaon' },
        { href: '', text: 'Luxury Penthouses in Gurgaon' },
        { href: '', text: 'Luxury Villas in Gurgaon' },
        { href: '', text: 'List of Top Developers in Gurgaon' },
        { href: '', text: 'Affordable Flats in Gurgaon' },
        { href: '', text: 'SCO Plots in Gurgaon' },
        { href: '', text: 'Commercial properties in Gurgaon' },
        { href: '', text: 'Apartments in Gurgaon' },
        { href: '', text: 'Residential Properties in Gurgaon' },
        { href: '', text: 'Villas for Sale in Gurgaon' },
        { href: '', text: 'Flats on Southern Peripheral Road' },
        { href: '', text: 'Flats in New Gurgaon' },
        { href: '', text: 'Flats on Sohna Road Gurgaon' },
        { href: '', text: 'Flats on Golf Course Ext. Road Gurgaon' },
        { href: '', text: 'Flats on Golf Course Road Gurgaon' },
        { href: '', text: 'Flats on Dwarka Expressway Gurgaon' },
        { href: '', text: 'Villas in Dwarka Expressway' },
        { href: '', text: 'Villas in Golf course Extension Road' },
        { href: '', text: 'Villas in Golf Course Road' },
        { href: '', text: '2 bhk Flats in Gurgaon' },
        { href: '', text: '3 bhk Flats in Gurgaon' },
        { href: '', text: '5 bhk Flats in Gurgaon' },
        { href: '', text: '4 bhk Flats in Gurgaon' },
        { href: '', text: 'New Launch Projects in Gurgaon' },
        { href: '', text: 'Ready to Move Flats in Gurgaon' }
      ]
    },
    {
      title: 'Projects In Bangalore',
      links: [
        { href: '', text: 'Luxury Bungalows in Bangalore' },
        { href: '', text: 'Luxury Apartments in Hebbal' },
        { href: '', text: 'Luxury flats in Bangalore' },
        { href: '', text: 'Luxury Penthouses in Bangalore' },
        { href: '', text: 'Luxury Projects in Yelahanka' },
        { href: '', text: 'Luxury Villas in Bangalore' },
        { href: '', text: 'Luxury Villas in Devanahalli' },
        { href: '', text: 'Luxury Plots in Bangalore' },
        { href: '', text: 'Ready to Move Villas in Bangalore' },
        { href: '', text: 'Villaments In Bangalore' },
        { href: '', text: 'Luxury Villas in Yelahanka' },
        { href: '', text: 'Luxury Projects in Bangalore' },
        { href: '', text: 'Ready to Move Projects in Bangalore' },
        { href: '', text: 'Luxury Flats in Yelahanka' },
        { href: '', text: 'Luxury Flats in Devanahalli' },
        { href: '', text: 'Apartments in Bangalore' },
        { href: '', text: 'Ready to Move Flats in Bangalore' },
        { href: '', text: 'New Launch Projects in Bangalore' }
      ]
    },
    {
      title: 'Projects In Goa',
      links: [
        { href: '', text: 'Luxury Projects in Goa' },
        { href: '', text: 'Luxury Villas in Goa' },
        { href: '', text: 'Luxury Plots in Goa' },
        { href: '', text: 'Ready to Move Villas in Goa' },
        { href: '', text: 'New Launch Projects in Bangalore' }
      ]
    },
    {
      title: 'Projects In Delhi',
      links: [
        { href: '', text: 'Luxury Duplexes In Delhi' },
        { href: '', text: 'Apartments In Delhi' },
        { href: '', text: '2 bhk Flats In Delhi' },
        { href: '', text: '3 bhk Flats In Delhi' },
        { href: '', text: '4 bhk Flats In Delhi' },
        { href: '', text: '5 bhk Flats In Delhi' },
        { href: '', text: 'Ready to Move Flats In Delhi' },
        { href: '', text: 'New Launch Projects In Delhi' }
      ]
    },
    {
      title: 'Projects In Mumbai',
      links: [
        { href: '', text: 'Apartments In Mumba' },
        { href: '', text: '2 bhk Flats In Mumbai' },
        { href: '', text: '3 bhk Flats In Mumbai' },
        { href: '', text: '4 bhk Flats In Mumbai' },
        { href: '', text: '5 bhk Flats In Mumbai' }
      ]
    }
  ];

  return (
    <div className="je2-popular-links" style={{ marginLeft: '2rem' }}>
      <h2 className="je2-texts__heading-34" style={{ textAlign: 'left' }}>
        Popular Links
      </h2>
      <div className="je2-popular-links__grid _6">
        {linksData.map((section, index) => (
          <PopularLinksSection key={index} title={section.title} links={section.links} />
        ))}
      </div>
    </div>
  );
};

export default PopularLinks;
