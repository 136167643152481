import React from 'react'

function Buy() {
    return (
        <div>
            <body>
                <div className="main-wrapper">
                 
                    <div className="page-wrapper management-page">
                        <div className="wrapper">
                            <div className="container container-sm">
                                <ul className="breadcrumb">
                                    <li>
                                        <a className="back-link" href="index.html">Back</a>
                                    </li>
                                    <li className="home">
                                        <a
                                            href="index.html">Main</a>
                                    </li>
                                    <li>Buy</li>
                                </ul>
                                <h1 className="title-lg">Buy</h1>
                                <div className="markdown">
                                    <p>Dubai is regarded as one of the top locations on earth for a variety of pursuits, including residing, vacationing, and investing. Additionally, it boasts the distinction of being the safest city in the entire globe. Its social, economic, and service-friendly climate provides residents and investors with many benefits not found in other nations, making it one of the most alluring areas to invest in a range of industries, particularly real estate.</p>
                                    <h4>INVESTMENT ADVANTAGES</h4>
                                    <ul>
                                        <li>HIGH RETURN ON INVESTMENT (ROI)</li>
                                        <li>AVAILABILITY OF AFFORDABLE OFF-PLAN PROPERTY</li>
                                        <li>AVAILABILITY OF FREEHOLD AREAS</li>
                                        <li>CONSISTENT DEVELOPMENT</li>
                                        <li>DUBAI PROPERTY VISA</li>
                                        <li>TECHNOLOGICAL ADVANCEMENTS</li>
                                        <li>ONE OF THE SAFEST CITIES IN THE WORLD</li>
                                    </ul>
                                    <p>Choosing a suitable area to buy property in Dubai depends on your priorities, family size, budgetary constraints and if you are an investor, your financial goals. Fortunately, the range of affordable and unique residential communities makes the city a haven for property investors. From affordable 1-bedroom apartments to luxurious villas and palatial townhouses for sale, there is something for every emirate property investor or home seeker. With several new projects in the pipeline, Dubai has a promising real estate market with attractive prices. And that&rsquo;s precisely why the time is certainly ripe for property investment in Dubai.</p>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper bg-gray py-5">
                            <div className="container container-sm">
                                <div className="request">
                                    <div className="request-left">
                                        <div className="request-title">
                                            <h2>Make a <span>Request</span></h2>
                                            <p>We cooperate with the most proven developers in Dubai</p>
                                        </div>
                                        <form className="request-form" id="management-form" method="POST"
                                            action="https://arbatrealty.com/orders">
                                            <input type="hidden" name="_token" value="KOfYFMZSHu6QgZy99OgdpfHwmpevggP5Jb3BUCXz"/>        <input id="object" name="object" type="hidden" value="" />
                                                <div className="half-form">
                                                    <div className="form-group">
                                                        <i className="icon icon-user"></i>
                                                        <input id="name" name="name" type="text"
                                                            className=" is-valid " placeholder="Your name"
                                                            value="" />
                                                    </div>
                                                    <div className="form-group">
                                                        <i className="icon icon-smartphone"></i>
                                                        <input id="phone" name="phone" type="text" value=""
                                                            className="phone-mask  is-valid " placeholder="Phone number" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <i className="icon icon-email"></i>
                                                    <input id="email" name="email" type="email"
                                                        className=" is-valid " placeholder="E-mail"
                                                        value="" />
                                                </div>
                                                <div className="form-group textarea-form">
                                                    <i className="icon icon-text"></i>
                                                    <textarea id="message" name="message" rows="4" placeholder="Message"
                                                        className=" is-valid "></textarea>
                                                </div>
                                                <input type="hidden" name="g-recaptcha-response" id="g-recaptcha-response-65b6665d9b972"/>
                                                    <div className="request-form-bottom">
                                                        <div className="accept-form">
                                                            <label className="checkbox">
                                                                <input type="checkbox" checked id="accept" name="accept"

                                                                    className=" is-valid "/>
                                                                    <span className="checkmark"></span>
                                                            </label>
                                                            <p>I accept the <a href="#">Terms and Conditions</a></p>
                                                        </div>
                                                        <button className="btn btn-black" type="submit">
                                                            <span>Submit</span>
                                                            <i className="icon icon-send"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>

                                            <div className="request-right">
                                                <div className="request-img">
                                                    <img src="storage/settings/July2023/w19t5SHP6catfCCK7J2D.jpg" alt=""/>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
            </body>
        </div>
    )
}

export default Buy