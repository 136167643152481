import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import { useNavigate, Link } from 'react-router-dom';

function FeaturedProject() {
    const [filters, setFilters] = useState({
        price: null,
        location: "",
        possessionStatus: "",
        bhkType: "All",
        projectType: "",
    });

    const apiUrl = process.env.REACT_APP_API_URL;
    const url = process.env.REACT_APP_URL;

    const navigate = useNavigate();
    const [viewport, setViewport] = useState({
        latitude: 0,
        longitude: 0,
        zoom: 14,
        width: window.innerWidth / 2,
        height: window.innerHeight,
    });
    const [project, setProject] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);
    const mapRef = useRef();

    useEffect(() => {
        if (!apiUrl) return;
        
        const fetchProject = async () => {
            try {
                const response = await fetch(`${apiUrl}/project_all`);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const result = await response.json();
                
                if (!result.data || result.data.length === 0) {
                    throw new Error('No data found');
                }

                const projects = result.data.slice(0, 3);
                setProject(projects);

                const validProject = projects.find(proj => proj.project_address && proj.project_address[0]);
                if (validProject) {
                    setViewport((prevState) => ({
                        ...prevState,
                        latitude: validProject.project_address[0].latitude || 0,
                        longitude: validProject.project_address[0].longitude || 0,
                    }));
                }

                setDataFetched(true);
                setError(null);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (!dataFetched) fetchProject();

        const handleResize = () => {
            setViewport((prevState) => ({
                ...prevState,
                width: window.innerWidth / 2,
                height: window.innerHeight,
            }));
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [apiUrl, dataFetched]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="NEWLAUNCHES" style={{ display: "flex", overflow: "hidden", scrollBehavior: "auto" }}>
            {project.length === 0 ? (
                <div>No projects found</div>
            ) : (
                project.map((property) => (
                    <div
                        key={property.project_id}
                        className="apartments-card-sm"
                        style={{
                            background: "#f8f8f8",
                            boxShadow: "0 3px 0 rgba(0, 0, 0, .16)",
                            borderRadius: "12px",
                            width: "calc(101.3% - 14px)",
                            margin: "28px 7px",
                            position: "relative",
                        }}
                    >
                        <div className="apartments-img">
                            <img
                                src={`${apiUrl}/image/${property.featured_image}`}
                                alt={property.featured_image}
                                loading="lazy" // Add lazy loading for images
                                width="100%" // Adjust to your layout
                                height="auto" // Adjust to your layout
                            />
                            <div className="badge-list-vertical">
                                <span className="badge info">{property.possession_status}</span>
                            </div>
                            <div>
                                <div className="badge-list">
                                    <span className="badge" style={{ marginLeft: "auto" }}>
                                        {property.project_type}
                                    </span>
                                </div>
                                <div style={{ display: "flex", marginTop: "0.5rem" }}>
                                    <span className="price-badge" style={{ marginLeft: "auto" }}>
                                        {property.price_start}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-caption">
                            <div className="apartments-title">
                                <p>{property.project_name}</p>
                            </div>
                            <div className="apartments-address warning">
                                <i className="icon icon-location-black"></i>
                                <p>{property.project_address?.[0]?.city || 'N/A'}</p>
                            </div>
                        </div>
                        <Link to={`${url}/index1/${property.project_id}`}
                            className="absolute-link"
                        ></Link>
                    </div>
                ))
            )}
        </div>
    );
}

export default FeaturedProject;
