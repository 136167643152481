import React, { useState } from "react";
import "./Form.css";

function RequestForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    date: "", // Initialize date field
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      alert(data.message);

      // Reset the form
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
        date: "", // Reset date
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      id="GetInTouchForm"
      className="grid__item default--1-1 desk--1-2 push--desk--1-12"
      style={{ position: "sticky", top: "39px" }}
    >
      <div>
        <section id="ContactForm-cc107cd3" className="ContactForm">
          <form onSubmit={handleSubmit} noValidate>
            <div className="ContactForm__wrapper grid">
              <div className="ContactForm__title grid__item header3">
                <h3>Book Your Site Visit</h3>
              </div>
              <div className="ContactForm__inputs grid__item">
                <div className="grid">
                  <div className="ContactForm__inputs-item grid__item desk--1-2">
                    <div className="InputForm">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        aria-label="First Name"
                        className="h6 input--border-bottom"
                      />
                    </div>
                  </div>
                  <div className="ContactForm__inputs-item grid__item desk--1-2">
                    <div className="InputForm">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        aria-label="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        className="h6 input--border-bottom"
                      />
                    </div>
                  </div>
                  <div className="ContactForm__inputs-item grid__item desk--1-2">
                    <div className="InputForm">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        pattern="[0-9]*"
                        placeholder="Phone number (Optional)"
                        value={formData.phone}
                        onChange={handleChange}
                        className="h6 input--border-bottom"
                      />
                    </div>
                  </div>

                  {/* Date input field */}
                  <div className="ContactForm__inputs-item grid__item desk--1-2">
                    <div className="InputForm">
                      <input
                        type="date"
                        id="date"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                        className="h6 input--border-bottom"
                      />
                    </div>
                  </div>

                  <div className="ContactForm__inputs-item grid__item ContactForm__message">
                    <textarea
                      id="message"
                      name="message"
                      placeholder="Write Message"
                      value={formData.message}
                      onChange={handleChange}
                      className="ContactForm__textarea header6"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="ContactForm__captcha grid__item">
                <span className="small">
                  By submitting this form, you acknowledge that you accept the Sotheby's International Realty
                  <a
                    href="https:/#/Index1"
                    target="_blank"
                    aria-label="privacy policy"
                  >
                    Privacy Policy
                  </a> and
                  <a
                    href=""
                    target="_blank"
                    aria-label="terms of use"
                  >
                    Terms of Use
                  </a>.
                  <br />
                  <br />
                  This site is protected by reCAPTCHA and the Google
                  <a
                    style={{ color: "black" }}
                    href=""
                    target="_blank"
                    aria-label="google privacy policy"
                  >
                    Privacy Policy
                  </a> and
                  <a href="" target="_blank" aria-label="google terms of service">
                    Terms of Service
                  </a> apply.
                </span>
              </div>
              <div className="ContactForm__button grid__item">
                <button style={{ background: "#eeecea" }} type="submit">
                  <div className="ContactForm__submit">
                    <div className="btn btn--with-arrow ContactForm__submit-btn u-text-uppercase u-focus btn--primary btn--sir-blue o-button form__submit btn-Inquire js-email-agent-input">
                      <span className="">
                        <span className="btn__text">Send message</span>
                        <i className="btn__icon-arrow icon icon-arroweditable arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </button>
              </div>
              <div className="ContactForm__disclaimer grid__item">
                <span className="small">
                  Yes, I would like more information from Sotheby's International Realty. Please use and/or share my information with a
                  <em>Sotheby's International Realty</em> agent to contact me about my real estate needs.
                  <br />
                  <br />
                  By clicking SEND MESSAGE, I agree a <em>Sotheby's International Realty</em> Agent may contact me by phone or text message including by automated means about real estate services, and that I can access real estate services without providing my phone number.
                  I acknowledge that I have read and agree to the
                  <a style={{ color: "black" }} href="" aria-label="sotheby's privacy policy">
                    Terms of Use
                  </a> and
                  <a style={{ color: "black" }} href="" aria-label="sotheby's terms of service.">
                    Privacy Policy
                  </a>
                </span>
              </div>
            </div>
          </form>
          <div className="ContactForm__thanks desk--5-6 lap--6-8" style={{ display: "none" }}>
            <p id="PropertyDetails" className="ContactForm__thanks-message header3">
              Thank you for your interest. One of our agents will be in touch with you soon.
            </p>
            <div>
              <a href="" target="_self" className="btn btn--with-arrow btn--tertiary btn--white u-no-padding-left u-text-uppercase">
                <span className="btn__container">
                  <span className="btn__text">Send Another Message</span>
                  <i className="btn__icon-arrow icon icon-arroweditable arrow-right"></i>
                </span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default RequestForm;
