import React from 'react'

function Listing () {
  return (
    <div>
      <body>
    <div id="__next">
        <div data-layout="fullscreen" style={{lpsnackbarpositiontop:"var(--header-with-filters-height)"}}>
            <div class="__className_5148cd">
                <nav class="Navigation_wrapper__ZzR6r" aria-label="Main navigation"
                    itemType="https://schema.org/SiteNavigationElement" itemscope="" data-test-id="navigation"
                    style={{color:"#ffffff",backgroundColor:"#000000",justifyContent:"flex-end;align-items:center"}}>
                    <div class="Flex_align-center__1Cb1Y Flex_gap-xl__biF_G Base_display-flex__2s2Hu"></div>
                    <div class="Navigation_toggleContainer__7PJLp"><button type="button"
                            class="Button_icon__5X1Vf Navigation_toggle__TPiv4 Button_fullwidth__tptZp"
                            data-size="regular" data-variant="icon" data-test-id="navbar-mobile-menu-trigger"
                            aria-hidden="false" aria-controls=":R6cm:" aria-expanded="false" data-state="closed"><span
                                style={{position:"absolute",border:"0",width:"1px",height:"1px",padding:"0",margin:"-1px",overflow:"hidden",clip:"rect(0, 0, 0, 0)",whitespace:"nowrap",ordwrap:"normal"}}>Open
                                navigation</span><span class="Navigation_icon____3az"><span
                                    style={{backgroundColor:"#ffffff"}}></span><span
                                    style={{backgroundColor:"#ffffff"}}></span></span></button></div>
                    <div data-test-id="navbar-menu-items" id=":R6cm:" data-state="closed"
                        class="Navigation_navigation__C5PNs Base_display-flex__2s2Hu Base_pt-medium__9Xszi Base_pb-medium__ur5V6">
                        <a href="/properties/sale" data-variant="no-underline" rel="noopener"
                            class="Link_link__Yefdy NavigationItems_item__Fbfia">Our Properties</a><a href="/about"
                            data-variant="no-underline" rel="noopener"
                            class="Link_link__Yefdy NavigationItems_item__Fbfia">Meet The Team</a>
                        <button type="button"
                            class="Button_link__NhCcp NavigationItems_item__Fbfia Button_fullwidth__tptZp"
                            data-size="regular" data-variant="link">Contact Us</button><a href="tel:7478880508"
                            data-variant="no-underline" rel="noopener"
                            class="Link_link__Yefdy NavigationItems_item__Fbfia">(747) 888-0508</a><button type="button"
                            class="Button_link__NhCcp Navigation_login__SJ8QL Button_fullwidth__tptZp"
                            data-size="regular" data-variant="link" data-test-id="log-in-button">Log In</button>
                    </div>
                </nav>
                <div class="Layout_main__Fn5Ho">
                    <main>
                        <div class="Listings_container__qSo98 Listings_containerHeightWithoutFooter__STreY"
                            data-view="list" data-test-id="listings-scene">
                            <div class="Listings_filters__9cZk5">
                                <form class="Filters_form__f573C">
                                    <fieldset class="Form_fieldset__Zxoq8">
                                        <div class="Filters_container__ZDRLz">
                                            <div class="Search_container__tB5Lk Search_search__gf10f" type="button"
                                                aria-haspopup="dialog" aria-expanded="false"
                                                aria-controls="radix-:R5dacm:" data-state="closed"
                                                data-lp-popover-trigger="true"><svg xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24" fill="none" viewBox="0 0 24 24"
                                                    aria-hidden="true" focusable="false" tabindex="-1"
                                                    aria-labelledby="">
                                                    <path fill="currentColor" fill-rule="evenodd"
                                                        d="M8 11a5 5 0 1 0 10 0 5 5 0 0 0-10 0Zm12 0a7 7 0 0 1-10.594 6.008l-3.699 3.7a1 1 0 0 1-1.414-1.415l3.554-3.555A7 7 0 1 1 20 11Z"
                                                        clip-rule="evenodd"></path>
                                                </svg><input class="Search_input__KlfWT Search_search__gf10f"
                                                    data-test-id="omni-search" placeholder="Search" value="" /></div>
                                            <div class="Filters_filters__tq_Ki">
                                                <div class="Filters_fields__cnGTf">
                                                    <div class="Switch_switch__i1ZWJ ListingType_switch__mSD8C"><label
                                                            for=":R2pdacm:" class="Switch_label__Uwutu">For
                                                            Sale</label><input type="radio" id=":R2pdacm:"
                                                            class="Switch_input__lVZjk" name=":R2pdacmH2:" checked=""
                                                            value="false" /><label for=":R2pdacmH1:"
                                                            class="Switch_label__Uwutu">For Rent</label><input
                                                            type="radio" id=":R2pdacmH1:" class="Switch_input__lVZjk"
                                                            name=":R2pdacmH2:" value="true" /></div><button
                                                        type="button"
                                                        class="Button_dropdown__F3xMk SelectPriceRange_trigger__kc8Eg Button_fullwidth__tptZp"
                                                        data-size="regular" data-variant="dropdown"
                                                        aria-haspopup="dialog" aria-expanded="false"
                                                        aria-controls="radix-:R4pdacm:" data-state="closed"
                                                        data-lp-popover-trigger="true"><span>Any Price</span>
                                                        <div class="Expander_expander__9LBme"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="none" viewBox="0 0 24 24"
                                                                aria-hidden="true" focusable="false" tabindex="-1"
                                                                aria-labelledby="">
                                                                <path stroke="currentColor" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2"
                                                                    d="m19 9-7 7-7-7"></path>
                                                            </svg></div>
                                                    </button>
                                                    <div class="SelectBeds_container__KCdR_"><button type="button"
                                                            role="combobox" aria-controls="radix-:R6pdacm:"
                                                            aria-expanded="false" aria-autocomplete="none" dir="ltr"
                                                            data-state="closed" class="Select_trigger__X_dKT"
                                                            data-size="small" data-variant="default"><span
                                                                style={{pointerevents:"none"}}>0+ Beds</span><span
                                                                aria-hidden="true" class="Select_icon__ckUU_"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="16"
                                                                    height="16" fill="none" viewBox="0 0 24 24"
                                                                    aria-hidden="true" focusable="false" tabindex="-1"
                                                                    aria-labelledby="">
                                                                    <path stroke="currentColor" stroke-linecap="round"
                                                                        stroke-linejoin="round" stroke-width="2"
                                                                        d="m19 9-7 7-7-7"></path>
                                                                </svg></span></button>
                                                        <select aria-hidden="true" tabindex="-1"
                                                            style={{position:"absolute",border:"0",width:"1px",height:"1px",padding:"0",margin:"-1px",overflow:"hidden",clip:"React(0, 0, 0, 0)",whitespace:"nowrap",wordwrap:"normal"}}></select>
                                                    </div><button type="button" role="combobox"
                                                        aria-controls="radix-:R8pdacm:" aria-expanded="false"
                                                        aria-autocomplete="none" dir="ltr" data-state="closed"
                                                        class="Select_trigger__X_dKT" data-size="small"
                                                        data-variant="default"><span style={{pointerevents:"none"}}>0+
                                                            Baths</span><span aria-hidden="true"
                                                            class="Select_icon__ckUU_"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="none" viewBox="0 0 24 24"
                                                                aria-hidden="true" focusable="false" tabindex="-1"
                                                                aria-labelledby="">
                                                                <path stroke="currentColor" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2"
                                                                    d="m19 9-7 7-7-7"></path>
                                                            </svg></span></button>
                                                    <select aria-hidden="true" tabindex="-1"
                                                        style={{position:"absolute",border:"0",width:"1px",height:"1px",padding:"0",margin:"-1px",overflow:"hidden",clip:"React(0, 0, 0, 0)",whitespace:"nowrap",wordwrap:"normal"}}></select><button
                                                        type="button"
                                                        class="Button_dropdown__F3xMk SelectPropertyTypes_trigger___f60h Button_fullwidth__tptZp"
                                                        data-size="regular" data-variant="dropdown"
                                                        aria-haspopup="dialog" aria-expanded="false"
                                                        aria-controls="radix-:Rapdacm:" data-state="closed"
                                                        data-lp-popover-trigger="true">Any Property Type<div
                                                            class="Expander_expander__9LBme"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="none" viewBox="0 0 24 24"
                                                                aria-hidden="true" focusable="false" tabindex="-1"
                                                                aria-labelledby="">
                                                                <path stroke="currentColor" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2"
                                                                    d="m19 9-7 7-7-7"></path>
                                                            </svg></div></button>
                                                </div>
                                                <button type="button"
                                                    class="Button_dropdown__F3xMk Filters_trigger__mM5m6 Button_fullwidth__tptZp"
                                                    data-size="regular" data-variant="dropdown"
                                                    data-test-id="more-filters-trigger" aria-haspopup="dialog"
                                                    aria-expanded="false" aria-controls="radix-:R19dacm:"
                                                    data-state="closed"><span
                                                        class="Filters_tablet-only__cbBSW">Filters</span><span
                                                        class="Filters_laptop-only__kpqgC">More Filters</span><span
                                                        class="Filters_desktop-only__YSQpM">More Filters</span></button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form><button type="button"
                                    class="Button_dropdown__F3xMk SavedSearch_trigger__11L_T Button_fullwidth__tptZp"
                                    data-size="regular" data-variant="dropdown"
                                    data-test-id="saved-search-trigger-logged-out">Save Search</button>
                            </div>
                            <div class="Listings_content__OW2C7">
                                <div dir="ltr" class="Listings_sidebar__y6mwd"
                                    style={{position:"relative",radixscrollareacornerwidth:"0px",radixscrollareacornerheight:"0px"}}>
                                    <style>
                                        [data-radix-scroll-area-viewport] {{
                                            scrollbars: "none",
                                            msoverflowstyle: "none",
                                            webkitoverflowscrolling: "touch"
                                        }}

                                        [data-radix-scroll-area-viewport]::-webkit-scrollbar {{
                                            display: "none"
                                        }}
                                    </style>
                                    <div data-radix-scroll-area-viewport="" class="VerticalScroll_viewport__IJeAx"
                                        style={{overflowx:"hidden",overflowy:"hidden"}}>
                                        <div style={{minWidth:"100%",display:"table"}}>
                                            <div
                                                class="Listings_sidebar__inner__6FGf_ Flex_direction-column__Klwyo Flex_justify-between__P1_bt Flex_gap-base__9oCWw Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-base__icwl_ Base_pb-base__Fou7Z Base_pl-base__is7gk">
                                                <div class="ListingSort_sort__5mdNy"><label class="Label_label__ZMwUT">
                                                        <p class="Text_text__YABjM">Sort:</p><button type="button"
                                                            role="combobox" aria-controls="radix-:R4l5lacm:"
                                                            aria-expanded="false" aria-autocomplete="none" dir="ltr"
                                                            data-state="closed"
                                                            class="Select_trigger__X_dKT SelectSort_sort__RNFc5"
                                                            data-size="small" data-variant="default"><span
                                                                style={{pointerevents:"none"}}>Newest</span><span
                                                                aria-hidden="true" class="Select_icon__ckUU_"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="16"
                                                                    height="16" fill="none" viewBox="0 0 24 24"
                                                                    aria-hidden="true" focusable="false" tabindex="-1"
                                                                    aria-labelledby="">
                                                                    <path stroke="currentColor" stroke-linecap="round"
                                                                        stroke-linejoin="round" stroke-width="2"
                                                                        d="m19 9-7 7-7-7"></path>
                                                                </svg></span></button><select aria-hidden="true"
                                                            tabindex="-1"
                                                            style={{position:"absolute",border:"0",width:"1px",height:"1px",padding:"0",margin:"-1px",overflow:"hidden",clip:"React(0, 0, 0, 0)",whitespace:"nowrap",wordwrap:"normal"}}></select>
                                                    </label>
                                                    <p class="Text_text__YABjM Text_weight-bold__nnN1u"></p>
                                                </div>
                                                <div class="ListingGrid_grid__3AqFd">
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ListingInformationCard_loading__xVAPX ListingInformationCard_card__L37kD ListingInformationCard_border__VqsvW Flex_direction-column__Klwyo Box_bg-base__NIXu3 Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP Base_br-small__5v_21 Base_bl-small__Robft">
                                                        <div
                                                            class="ListingInformationCard_header__grDp8 Flex_direction-column__Klwyo Base_display-flex__2s2Hu Base_tl-small__OKNsk Base_tr-small__gpQiP">
                                                            <div style={{height:"190px"}}
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_loadingImage__89ceJ Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="ListingInformationCard_body__T65Ik Flex_direction-column__Klwyo Flex_gap-8__k_oPJ Box_border-color-base__APNCY Box_border-size-none__5kc_n Box_border-top-none___Wk0R Base_display-flex__2s2Hu Base_pt-base__8Vwz7 Base_pr-none__gQGSb Base_pb-none__55f8s Base_pl-none__3yhWe Base_tl-none__Aafo0 Base_tr-none__XdwuD Base_br-small__5v_21 Base_bl-small__Robft">
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg ListingInformationCard_title__3PNHe Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                            <div data-size="half"
                                                                class="Skeleton_skeleton__v_vY_ ListingInformationCard_bar__AJ0cg Base_display-flex__2s2Hu">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="Listings_map__u_uOU">
                                    <div class="Map_interactiveMap__LhXql">
                                        <div class="Skeleton_skeleton__v_vY_ Base_display-flex__2s2Hu"></div>
                                    </div>
                                </div>
                                <div class="Listings_floating__RSsh_"><button type="button"
                                        class="Button_primary__HSaZ1 Button_fullwidth__tptZp" data-size="regular"
                                        data-variant="primary"><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                            height="16" fill="none" viewBox="0 0 24 24" aria-hidden="true"
                                            focusable="false" tabindex="-1" aria-labelledby="">
                                            <path fill="currentColor"
                                                d="M13.774 4.086 9.88 6.202c-.376.202-.657.7-.657 1.107v12.213c0 .405.233.573.61.37l3.978-2.101c.376-.204.744-.701.744-1.107V4.456c0-.406-.405-.572-.781-.37ZM8.332 7.332c0-.407-.342-.905-.719-1.107L4.604 4.573C3.731 4.102 3 4.489 3 5.43v10.28c0 .943.714 2.096 1.588 2.564l3.025 1.641c.376.202.719.036.719-.37V7.332Zm10.875-1.51-3.16-1.714c-.377-.203-.601-.036-.601.37v12.228c0 .406.095.88.516 1.117 0 0 3.307 1.753 3.378 1.785.761.311 1.438-.076 1.438-.94V8.286c0-.88-.748-1.98-1.57-2.463Z">
                                            </path>
                                        </svg>
                                        {/* View <!-- -->Map */}
                                         </button></div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <div role="region" aria-label="Notifications (F8)" tabindex="-1" style={{pointerevents:"none"}}>
                <ol tabindex="-1" class="Snackbar_container__aVu8G Snackbar_snackbar__4x41O"></ol>
            </div>
        </div>
    </div>

</body>
    </div>
  )
}

export default Listing 
