import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "@fancyapps/ui/dist/fancybox/fancybox.css";  // Adjust the path based on your installation
import { Fancybox } from "@fancyapps/ui";

Fancybox.bind("[data-fancybox='gallery']", {
  // Options go here
});

function GalleryImage() {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { project_id } = useParams();

  useEffect(() => {
    console.log("Component mounted. apiUrl:", apiUrl);
    console.log("project_id:", project_id);

    if (!apiUrl || !project_id) {
      setError("API URL or project ID is missing.");
      setLoading(false);
      return;
    }

    async function fetchProject() {
      try {
        console.log("Fetching project with ID:", project_id);
        const response = await fetch(`${apiUrl}/projects/${project_id}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Response Data:", result);

        setProject(result.data);
        setError(null); // Clear previous errors
      } catch (err) {
        console.error("Fetch Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchProject();
  }, [apiUrl, project_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!project || !project.gallery_image || project.gallery_image.length === 0) {
    return <div>No gallery images found.</div>;
  }

  return (
    <div className="project-slider swiper">
      <div className="owl-carousel owl-theme">
        {project.gallery_image.map((gallery_image, index) => (
          <div
            key={index}
            className="project-slider-item"
            style={{ width: "22rem" }}
          >
            <a
              className="project-slider-img fancybox"
              href={`${apiUrl}/image/${gallery_image}`}
              data-fancybox="gallery"
              data-caption={`Gallery Image ${index + 1}`}
            >
              <img src={`${apiUrl}/image/${gallery_image}`} alt={`Gallery Image ${index + 1}`} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GalleryImage;
