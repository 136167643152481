import React from 'react';
import './Sidebar.css'; // Ensure you have this CSS file

function Sidebar({ toggleSidebar }) {
    return (
        <div className="mobile-sidebar-content">
            <button className="close-btn" onClick={toggleSidebar}>×</button>
            <ul className="mobile-sidebar-nav">
                <li><a href="/#/">Home</a></li>
                <li><a href="/#/">Developer/Off Plan</a></li>
                <li><a href="/#/">Resources</a></li>
                <li><a href="/#/">Buy</a></li>
                <li><a href="/#/">Career</a></li>
                <li><a href="/#/">Our Team</a></li>
                <li><a href="/#/contect">Contact Us</a></li>
            </ul>
        </div>
    );
}

export default Sidebar;
