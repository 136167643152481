// src/App.js
import React from 'react';
import EmbassyBoulevard from './EmbassyBoulevard';
import './Card1.css';

const Card1 = () => {
  return (
    <div className="slidr">
      <h1>3D Carousel View</h1>
      <EmbassyBoulevard />
    </div>
  );
};

export default Card1;
