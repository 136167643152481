import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

const App = () => {
  const qrValue = "https://luxuryroof.in/"; // The value to encode in the QR code
  const qrSize = 256; // The size of the QR code

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>QR Code Generator</h1>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <QRCodeSVG value={qrValue} width={qrSize} height={qrSize} />
      </div>
    </div>
  );
};

export default App;
