import React, { useState } from "react";
import "./Contectcss.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      alert(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="main-wrapper">
      <div className="page-wrapper contact-page">
        {/* <div className="breadcrumb-container">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <a className="back-link" href="index.html">
                  Back
                </a>
              </li>
              <li>
                <a href="index.html">Main</a>
              </li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div> */}
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.9899903149194!2d77.0360466740847!3d28.41955889372276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d229e71ef44dd%3A0x9931b80f30d32dd3!2sJMD%20Megapolis!5e0!3m2!1sen!2sin!4v1717066524565!5m2!1sen!2sin"
            width="100%"
            height="500"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="container">
          <div className="contact-wrapper">
            <div className="contact-details">
              <h6 className="contact-title">LuxuryRoof Gurugram</h6>
              <div className="contact-item">
                <h6 className="contact-item-title">Address:</h6>
                <div className="contact-item-text">
                  <i className="icon icon-pin-orange"></i>
                  <p>
                    Office 954, JMD MegaPolis, Sohna road Sector 48, Gurugram
                  </p>
                </div>
              </div>
              <div className="contact-item">
                <h6 className="contact-item-title">Phone:</h6>
                <div className="contact-item-text">
                  <i className="icon icon-phone-orange"></i>
                  <p>+ 90485 90485</p>
                  <a className="absolute-link" href="tel:+9048590485"></a>
                </div>
              </div>
              <div className="contact-item">
                <h6 className="contact-item-title">Email:</h6>
                <div className="contact-item-text">
                  <i className="icon icon-email-orange"></i>
                  <p>support@luxuryroof.in</p>
                  <a
                    href="mailto:support@luxuryroof.in"
                    className="absolute-link"
                  ></a>
                </div>
              </div>
              <div className="contact-item">
                <h6 className="contact-item-title">Post box:</h6>
                <div className="contact-item-text">
                  <p>Gurugram, Haryana 122018</p>
                </div>
              </div>
            </div>
            <div className="request-form-container">
              <div className="request-title">
                <h2>
                  Make a <span>Request</span>
                </h2>
                <p>We cooperate with the most proven developers in Gurgaon</p>
              </div>
              <form
                className="request-form"
                onSubmit={handleSubmit}
              >
                <div className="form-group-half">
                  <div className="form-group">
                    <i className="icon icon-user"></i>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Your name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <i className="icon icon-phone"></i>
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder="Phone number"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <i className="icon icon-email"></i>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <i className="icon icon-text"></i>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="form-group">
                  <button className="btn-submit" type="submit">
                    <span>Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <div className="partners">
          <h6 className="partners-title">See Our Partners</h6>
          <div className="partners-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img
                  src="storage/partners/August2022/IhTeqSc29PcKMDhq8m4e.png"
                  alt="Partner 1"
                />
              </div>
              <div className="swiper-slide">
                <img
                  src="storage/partners/August2022/Wm1hNdCZTGmBQz26Unrl.jpg"
                  alt="Partner 2"
                />
              </div>
              <div className="swiper-slide">
                <img
                  src="storage/partners/August2022/6iii3TbCau4TOZEGg1ET.png"
                  alt="Partner 3"
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Contact;
