import React from 'react'

function Management() {
    return (
        <div>
            <body>
                <div class="main-wrapper">
                    
                    <div class="page-wrapper management-page">
                        <div class="wrapper">
                            <div class="container container-sm">
                                <ul class="breadcrumb">
                                    <li>
                                        <a class="back-link" href="index.html">Back</a>
                                    </li>
                                    <li class="home">
                                        <a
                                            href="index.html">Main</a>
                                    </li>
                                    <li>Property management</li>
                                </ul>
                                <h1 class="title-lg">Property management</h1>
                                <div class="management-progress">
                                    <div class="management-progress-item">
                                        <img src="storage/management/property/progress-agent.svg" alt=""/>
                                            <div class="caption">
                                                <h6><span>12</span> years</h6>
                                                <p>of productive work</p>
                                            </div>
                                    </div>
                                    <div class="management-progress-item">
                                        <img src="storage/management/property/progress-home.svg" alt=""/>
                                            <div class="caption">
                                                <h6><span>100+</span> Property Management</h6>
                                                <p>Rental Assurance for your Property</p>
                                            </div>
                                    </div>
                                    <div class="management-progress-item">
                                        <img src="storage/management/property/progress-bag.svg" alt=""/>
                                            <div class="caption">
                                                <h6><span> Premium  </span> Advertisement</h6>
                                                <p>Several Platforms of High Quality Marketing for your Properties</p>
                                            </div>
                                    </div>
                                    <div class="management-progress-item">
                                        <img src="storage/management/property/progress-agent.svg" alt=""/>
                                            <div class="caption">
                                                <h6><span>Top</span> Performing Agents</h6>
                                                <p>Who Specialize In Your Target Area</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="management-article-wrapper">
                                    <div class="management-article">
                                        <h6>Are you looking for a professional property management company that you can trust?</h6>
                                        <p>Owning or letting a property or building in Dubai can be both time consuming and demanding. Taking care of general maintenance, repairs, cleaning, and the mound of administration that&rsquo;s involved often eats up more of your month than anticipated. Fortunately, our Dubai Property Management service is a stress-free way to look after your villa, apartment, or entire building and its tenants!</p>
                                        <p>It is an ideal solution for owners and landlords based overseas, busy bees with limited time available, or clients who don&rsquo;t want to be involved in the day-to-day running of their bricks and mortar investment</p>
                                    </div>
                                    <div class="management-article">
                                        <h6>Discover our tailor-made, comprehensive Property Management packages</h6>
                                        <p>Our tailor-made, comprehensive Property Management package is an essential part of our lettings service. We provide a full range of specialist assistance for an affordable fee. Our clients can choose between two packages Basic and Premier. Customised solutions can be offered as well upon consultation. After finding you the right tenant(s), we look after you, your property or your building, and the tenant(s) through the term of the tenancy.</p>
                                        <p>We understand that we are dealing with people&rsquo;s homes and therefore our staff are not only professional and RERA qualified, but also empathetic when dealing with personal matters.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="service" id="service">
                            <div class="container container-sm">
                                <div class="row service-content">
                                    <div class="col-sm-6 service-left">
                                        <h1 class="service-title">Professional help <span>with a personal touch</span></h1>
                                    </div>
                                    <div class="col-sm-6 service-right">
                                    <p>
                  Welcome to <strong>Luxury Roof</strong>, where your dreams of living in a premium, luxury apartment come true.
                  </p>
                  <p>
                  We provide comprehensive property information and in-depth neighborhood data to help you make well-informed decisions about your new home or investment. Our dedicated team of specialists is committed to managing every aspect of your property needs—from marketing and contracting to day-to-day management—ensuring a seamless and stress-free experience for you.
                  </p>
                                    </div>
                                </div>
                                <div class="service-tooltips">
                                    <div class="service-tooltip tooltip-1">
                                        <div class="service-tooltip-img">
                                            <img src="storage/articles/June2022/62ba7ca5cc1e1.png" alt="What is the best property for investment?"/>
                                        </div>
                                        <div class="service-tooltip-caption">
                                            <h6>What is the best property for investment?</h6>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div class="service-tooltip tooltip-2">
                                        <div class="service-tooltip-img">
                                            <img src="storage/articles/June2022/62ba7ca5db35a.png" alt="What location gives highest ROI?"/>
                                        </div>
                                        <div class="service-tooltip-caption">
                                            <h6>What location gives highest ROI?</h6>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div class="service-tooltip tooltip-3">
                                        <div class="service-tooltip-img">
                                            <img src="storage/articles/June2022/62ba7ca5df50c.png" alt="Where should I buy a new home?"/>
                                        </div>
                                        <div class="service-tooltip-caption">
                                            <h6>Where should I buy a new home?</h6>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div class="service-tooltip tooltip-4">
                                        <div class="service-tooltip-img">
                                            <img src="storage/articles/June2022/62ba7ca601220.png" alt="Where is the best location to rent?"/>
                                        </div>
                                        <div class="service-tooltip-caption">
                                            <h6>Where is the best location to rent?</h6>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div class="service-tooltip tooltip-5">
                                        <div class="service-tooltip-img">
                                            <img src="storage/articles/June2022/62ba7ca603d31.png" alt="What documents I need to buy a property?"/>
                                        </div>
                                        <div class="service-tooltip-caption">
                                            <h6>What documents I need to buy a property?</h6>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div class="service-tooltip tooltip-6">
                                        <div class="service-tooltip-img">
                                            <img src="storage/articles/June2022/62ba7ca618a53.png" alt="Why should I buy OffPlan property?"/>
                                        </div>
                                        <div class="service-tooltip-caption">
                                            <h6>Why should I buy OffPlan property?</h6>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper bg-gray">
                            <div class="container container-sm">
                                <div class="request">
                                    <div class="request-left">
                                        <div class="request-title">
                                            <h2>Make a <span>Request</span></h2>
                                            <p>We cooperate with the most proven developers in Dubai</p>
                                        </div>
                                        <form class="request-form" id="management-form" method="POST"
                                            action="https://arbatrealty.com/orders">
                                            <input type="hidden" name="_token" value="KOfYFMZSHu6QgZy99OgdpfHwmpevggP5Jb3BUCXz"/>        <input id="object" name="object" type="hidden" value="" />
                                                <div class="half-form">
                                                    <div class="form-group">
                                                        <i class="icon icon-user"></i>
                                                        <input id="name" name="name" type="text"
                                                            class=" is-valid " placeholder="Your name"
                                                            value="" />
                                                    </div>
                                                    <div class="form-group">
                                                        <i class="icon icon-smartphone"></i>
                                                        <input id="phone" name="phone" type="text" value=""
                                                            class="phone-mask  is-valid " placeholder="Phone number" />
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <i class="icon icon-email"></i>
                                                    <input id="email" name="email" type="email"
                                                        class=" is-valid " placeholder="E-mail"
                                                        value="" />
                                                </div>
                                                <div class="form-group textarea-form">
                                                    <i class="icon icon-text"></i>
                                                    <textarea id="message" name="message" rows="4" placeholder="Message"
                                                        class=" is-valid "></textarea>
                                                </div>
                                                <input type="hidden" name="g-recaptcha-response" id="g-recaptcha-response-65b6665d2bae9"/>
                                                    <div class="request-form-bottom">
                                                        <div class="accept-form">
                                                            <label class="checkbox">
                                                                <input type="checkbox" checked id="accept" name="accept" class=" is-valid "/>
                                                                    <span class="checkmark"></span>
                                                            </label>
                                                            <p>I accept the <a href="#">Terms and Conditions</a></p>
                                                        </div>
                                                        <button class="btn btn-black" type="submit">
                                                            <span>Submit</span>
                                                            <i class="icon icon-send"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>

                                            <div class="request-right">
                                                <div class="request-img">
                                                    <img src="storage/settings/July2023/w19t5SHP6catfCCK7J2D.jpg" alt=""/>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>
            </body>
        </div>
    )
}

export default Management
