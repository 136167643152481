import React, { useState, useEffect, useCallback } from 'react';
import './Slider.css';

const HomeSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    { id: 0, imgSrc: 'https://example.com/image1.webp', alt: 'Slide 1' },
    { id: 1, imgSrc: 'https://example.com/image2.webp', alt: 'Slide 2' },
    { id: 2, imgSrc: 'https://example.com/image3.webp', alt: 'Slide 3' },
    // Add more slides as needed
  ];

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  }, [slides.length]);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  }, [slides.length]);

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Auto-slide every 5 seconds
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <div className="slider">
      <div className="sliderContent">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slider-item ${index === currentIndex ? 'active' : ''}`}
          >
            <img 
              src={slide.imgSrc} 
              alt={slide.alt} 
              loading="lazy"  // Lazy loading for images
            />
          </div>
        ))}
      </div>
      <button className="prev" onClick={prevSlide} aria-label="Previous Slide">
        &#10094;
      </button>
      <button className="next" onClick={nextSlide} aria-label="Next Slide">
        &#10095;
      </button>
      <ul className="pagination-indicator">
        {slides.map((slide, index) => (
          <li
            key={slide.id}
            className={index === currentIndex ? 'active' : ''}
            onClick={() => setCurrentIndex(index)}
            aria-label={`Go to slide ${index + 1}`}
          ></li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(HomeSlider);
