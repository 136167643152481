// PopupForm.js
import React, { useState } from 'react';
import './PopupFormcss.css';

const PopupForm = ({ closePopup }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      setSuccessMessage("Form submitted successfully!");

      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <h2>Let's get in touch</h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <label>
            Your Name
            <input type="text" name="name" value={formData.name} onChange={handleChange} />
          </label>
          <label>
            Email Address
            <input type="email" name="email" value={formData.email} onChange={handleChange} />
          </label>
          <label>
            Phone number (Optional)
            <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
          </label>
          <label>
            Write Message
            <textarea name="message" value={formData.message} onChange={handleChange}></textarea>
          </label>
          <button type="submit">Submit</button>
        </form>
        <button className="close-btn" onClick={closePopup}>Close</button>
      </div>
    </div>
  );
};

export default PopupForm;
